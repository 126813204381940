export const WISA_SYSTEM_CODE_TO_PRODUCT_ID = {
    'DC912A253D1E9BA40E2C597ED2376640': 6,
    'A02FFD91ECE5E7EFEB46DB8F10A74059': 9,
    'BCA82E41EE7B0833588399B1FCD177C7': 9,
    '335F5352088D7D9BF74191E006D8E24C': 15,
    '5A4B25AAED25C2EE1B74DE72DC03C14E': 15,
    '5B8ADD2A5D98B1A652EA7FD72D942DAC': 12,
    '01161AAA0B6D1345DD8FE4E481144D84': 32,
    '52720E003547C70561BF5E03B95AA99F': 3,
    'C3E878E27F52E2A57ACE4D9A76FD9ACF': 3,
    '18D8042386B79E2C279FD162DF0205C8': 3,
    '816B112C6105B3EBD537828A39AF4818': 3,
    '69CB3EA317A32C4E6143E665FDB20B14': 3,
    'BBF94B34EB32268ADA57A3BE5062FE7D': 3,
    'B83AAC23B9528732C23CC7352950E880': 56,
    'FB7B9FFA5462084C5F4E7E85A093E6D7': 15,
    '00EC53C4682D36F5C4359F4AE7BD7BA1': 15,
    '89F0FD5C927D466D6EC9A21B9AC34FFA': 15,
    '819F46E52C25763A55CC642422644317': 15,
    'B3967A0E938DC2A6340E258630FEBD5A': 59,
    'CD00692C3BFE59267D5ECFAC5310286C': 58,
    '6C524F9D5D7027454A783C841250BA71': 15,
    'BEED13602B9B0E6ECB5B568FF5058F07': 15,
    '138BB0696595B338AFBAB333C555292A': 15,
    '82CEC96096D4281B7C95CD7E74623496': 15,
    '758874998F5BD0C393DA094E1967A72B': 33,
    'E7B24B112A44FDD9EE93BDF998C6CA0E': 4,
    '142949DF56EA8AE0BE8B5306971900A4': 4,
    '01386BD6D8E091C2AB4C7C7DE644D37B': 35,
    '0BB4AEC1710521C12EE76289D9440817': 36,
    'AA942AB2BFA6EBDA4840E7360CE6E7EF': 68,
    '28F0B864598A1291557BED248A998D4E': 38,
    'F8C1F23D6A8D8D7904FC0EA8E066B3BB': 38,
    'E46DE7E1BCAACED9A54F1E9D0D2F800D': 38,
    'B7B16ECF8CA53723593894116071700C': 38,
    '8DD48D6A2E2CAD213179A3992C0BE53C': 11,
    // 'FFD52F3C7E12435A724A8F30FDDADD9C': 86,
    '70C639DF5E30BDEE440E4CDF599FEC2B': 11,
    'EDDEA82AD2755B24C4E168C5FC2EBD40': 15,
    'EF0D3930A7B6C95BD2B32ED45989C61F': 15,
    '950A4152C2B4AA3AD78BDD6B366CC179': 15,
    '0C74B7F78409A4022A2C4C5A5CA3EE19': 60,
    'BCBE3365E6AC95EA2C0343A2395834DD': 22,
    'EC8CE6ABB3E952A85B8551BA726A1227': 27,
    // '705F2172834666788607EFBFCA35AFB3': 121,
    '58238E9AE2DD305D79C2EBC8C1883422': 25,
    '57AEEE35C98205091E18D1140E9F38CF': 26,
    '8BF1211FD4B7B94528899DE0A43B9FB3': 19,
    'CF004FDC76FA1A4F25F62E0EB5261CA3': 17,
    '05049E90FA4F5039A8CADC6ACBB4B2CC': 18,
    '8EFB100A295C0C690931222FF4467BB8': 10,
    '555D6702C950ECB729A966504AF0A635': 7,
    '13FE9D84310E77F13A6D184DBF1232F3': 97,
    '9BE40CEE5B0EEE1462C82C6964087FF9': 13,
    '432ACA3A1E345E339F35A30C8F65EDCE': 15,
    'AD972F10E0800B49D76FED33A21F6698': 57,
    '04025959B191F8F9DE3F924F0940515F': 34,
    '5737C6EC2E0716F3D8A7A5C4E0DE0D9A': 55,
    'BC6DC48B743DC5D013B1ABAEBD2FAED2': 55,
    '3DD48AB31D016FFCBF3314DF2B3CB9CE': 15,
    'F61D6947467CCD3AA5AF24DB320235DD': 15,
    '9DFCD5E558DFA04AAF37F137A1D9D3E5': 15,
    '289DFF07669D7A23DE0EF88D2F7129E7': 31,
    'BE83AB3ECD0DB773EB2DC1B0A17836A1': 30,
    '4F6FFE13A5D75B2D6A3923922B3922E5': 15,
    'F73B76CE8949FE29BF2A537CFA420E8F': 15,
    '060AD92489947D410D897474079C1477': 23,
    '9DE6D14FFF9806D4BCD1EF555BE766CD': 64,
    '3FE94A002317B5F9259F82690AEEA4CD': 8,
    'E165421110BA03099A1C0393373C5B43': 29,
    // 'D1C38A09ACC34845C6BE3A127A5AACAF': 119,
    '0584CE565C824B7B7F50282D9A19945B': 15,
    'D9FC5B73A8D78FAD3D6DFFE419384E70': 16,
    'C058F544C737782DEACEFA532D9ADD4C': 5,
}