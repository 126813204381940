import styles from "components/cards/newCheckoutOptionCard.module.scss";
import Image from "next/image";

import PipeIcon from "images/icons/pipe.svg";
import {formattedPrice, numberWithComma} from "common/utils";
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import {useRouter} from "next/router";
import {order} from "api/store/orders";
import {useMemo} from "react";


export default function NewCheckoutOptionCard (props) {
    const {orderInfo, optionCountInfo, noTitleBar, selectedCoupons, isReservation, noBrandName, translation, currency} = props;
    const {t} = useTranslation("component-new-checkout-option-card");
    const reservationTime = isReservation ? orderInfo.reservation_time : null;
    const brandName = optionCountInfo.product.brand_name;
    const referralIdToNickname = props.referralIdToNickname || {};

    // concat all option ids that are excluded from coupon in selectedCoupons

    const couponExcludedOptionIds = useMemo(() => {
        let couponExcludedOptionIds = [];

        if (Boolean(selectedCoupons?.length > 0))  {
            for (let i = 0; i < selectedCoupons.length; i++) {
                couponExcludedOptionIds = couponExcludedOptionIds.concat(selectedCoupons[i].coupon_excluded_option_ids);
            }
        }

        return couponExcludedOptionIds;
    }, [selectedCoupons])
    // const couponExcludedOptionIds = selectedCoupons?.reduce((acc, coupon) => {
    //     return acc.concat(coupon.coupon_excluded_option_ids);
    // }, []) || [];

    const isCouponApplicable = optionCountInfo.is_coupon_applicable && !couponExcludedOptionIds.includes(optionCountInfo.id);

    const currencyMemo = useMemo(() => {
        return orderInfo?.currency || currency;
    }, [orderInfo?.currency])

    return (
        <>
            <div className={classNames(styles.container, noTitleBar ? styles.noBorder : undefined)}>
                {
                    !noTitleBar &&
                    <div className={styles.titleBar}>
                        <span className={styles.brand}>{brandName}</span>
                    </div>
                }
                <div className={classNames(styles.contentContainer, noTitleBar ? styles.smallPadding : undefined)}>
                    <div className={styles.imageWrapper}>
                        <Image unoptimized src={optionCountInfo.product.thumbnail_url}
                               width={76} height={76}
                               layout="fill" objectFit="cover"
                        />
                    </div>
                    <div className={styles.infoContainer}>
                        {optionCountInfo.referral_id && (optionCountInfo.referral_id in referralIdToNickname) &&
                            <span className={styles.sellerInfo}>#{referralIdToNickname[optionCountInfo.referral_id]}</span>
                        }
                        <span className={styles.title}>{noTitleBar ? (noBrandName ? '' : `[${brandName}]`) : ''}{optionCountInfo.product.name}</span>
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>{optionCountInfo.name}</span>
                            <>
                                <PipeIcon viewBox="0 0 16 16" className={styles.pipe} />
                                <span className={styles.optionName}>
                                    {isReservation ? reservationTime : t('options.getCount', {count: optionCountInfo.count})}
                                </span>
                            </>
                        </div>
                        <div className={styles.finalPrice}>
                            <div className={styles.priceContainer}>
                                <span className={styles.price}>{formattedPrice(optionCountInfo.discounted_price, currencyMemo)}</span>
                                <span className={styles.won}>{t(`common:currency.${currencyMemo}`)}</span>
                            </div>
                            {
                                !isCouponApplicable &&
                                <div className={styles.couponUnavailable}>
                                    {t('price.unAvailableCoupon')}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
