import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import TextInput from "components/input/TextInput";
import {PHONE_NUMBER_REGEX} from "components/modal/DeliveryAddressModal";
import {findNextInputAndFocus, isValidPhoneNumber} from "common/utils";
import {SelfVerificationButton} from "pages/mypage/my-info";
import FullButton from "components/buttons/FullButton";
import {
    KEY_ORDERER_NAME,
    KEY_ORDERER_PHONE_NUMBER,
    KEY_VERIFIED_PHONE_NUMBER
} from "pages/store/orders/[ordNum]/checkout";
import {useContext, useRef, useState} from "react";
import UserContext from "context/AuthContext";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import {toast} from "react-toastify";
import CloseCircle from "images/icons/close_circle_brand.svg";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import Modal from "components/Modal";
import {authTypeToLogo} from "pages/auth/sign-in";
import {redirectToLogin} from "common/redirect";
import {useRouter} from "next/router";


const CheckoutUserInfoSection = (props) => {
    const {title, isLoading, setIsLoading, formik, verificationNumber, setVerificationNumber, isVerificationRequired} = props;
    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const router = useRouter();

    const phoneNumberToSentRef = useRef({});


    const requestNumberVerification = async (phoneNumber) => {
        setIsLoading(true);
        try {
            const res = await Axios.post('v1/user/number-verification-request/', {
                phone_number: phoneNumber,
            });
            if (res.status < 400) {
                toast.info('인증코드가 전송되었습니다.');
            } else {
                toast.info(res.data.display_message || '인증번호 요청에 실패했어요. 잠시 후 다시 시도해주세요.');
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            toast.info('인증번호 요청에 실패했어요. 잠시 후 다시 시도해주세요.');
            captureException(e);
        }
        setIsLoading(false);
        phoneNumberToSentRef.current[phoneNumber] = true;
    }

    const confirmNumberVerification = async (verificationNumber) => {
        setIsLoading(true);
        try {
            const res = await Axios.post('v1/user/number-verification-confirm/', {
                phone_number: values[KEY_ORDERER_PHONE_NUMBER],
                verification_code: verificationNumber,
            });
            if (res.status < 400) {
                setFieldValue(KEY_VERIFIED_PHONE_NUMBER, res.data.phone_number);
                if (user) {
                    userContext.setUser(val => {
                        const newVal = JSON.parse(JSON.stringify(val));
                        newVal.phone_number = res.data.phone_number;
                        return newVal;
                    })
                }
                setTimeout(() => {
                    if (codeInputRef.current) {
                        findNextInputAndFocus(codeInputRef.current);
                    }
                }, 0);
            } else {
                if (res.status === 403) {
                    setModalUserInfo(res.data.extra);
                } else {
                    toast.info(res.data.display_message || '인증에 실패했어요. 잠시 후 다시 시도해주세요.');
                    captureMessage(JSON.stringify(res.data));
                }
            }
        } catch (e) {
            toast.info('인증에 실패했어요. 잠시 후 다시 시도해주세요.');
            captureException(e);
        }
        setIsLoading(false);
    }

    const phoneNumberInputRef = useRef();
    const codeInputRef = useRef();
    const countryCodeInputRef = useRef();

    const isVerified = isVerificationRequired && values[KEY_VERIFIED_PHONE_NUMBER] && values[KEY_ORDERER_PHONE_NUMBER] && values[KEY_ORDERER_PHONE_NUMBER].split('-').join('') === values[KEY_VERIFIED_PHONE_NUMBER].split('-').join('');

    const [modalUserInfo, setModalUserInfo] = useState(undefined);

    const LogoComponent = authTypeToLogo[modalUserInfo?.auth_type];

    return (
        <div className={styles.section}>
            <Modal isOpen={!!modalUserInfo} close={() => setModalUserInfo(undefined)} width={300} round>
                <div className={styles.existModalContainer}>
                    <span className={styles.existModalText}>
                        {
                            !!user ?
                                <>다른 계정이<br/>사용 중인 번호에요</>
                                :
                                <>해당 번호로<br/>가입된 계정이 있어요</>
                        }
                    </span>
                    <div className={styles.existModalAccountInfoContainer}>
                        {
                            LogoComponent &&
                            <LogoComponent width={24} height={24} style={{marginRight: 8}} />
                        }
                        <span className={styles.existModalEmail}>{modalUserInfo?.email}</span>
                    </div>
                    {
                        !!user ?
                            <>
                                <SelfVerificationButton>
                                    <FullButton onClick={() => {setModalUserInfo(undefined)}} height={44} fontSize={15}>본인인증하고 번호 사용하기</FullButton>
                                </SelfVerificationButton>
                                <div style={{height: 8}} />
                                <FullButton height={44} fontSize={15} white onClick={() => {
                                    redirectToLogin(router, true, undefined);
                                }}>다른 계정으로 로그인하기</FullButton>
                            </>
                            :
                            <>
                                <FullButton height={44} fontSize={15} onClick={() => {
                                    redirectToLogin(router, true, undefined);
                                }}>기존 계정으로 로그인하기</FullButton>
                                <div style={{height: 8}} />
                                <FullButton height={44} fontSize={15} white onClick={() => {
                                    setFieldValue(KEY_VERIFIED_PHONE_NUMBER, values[KEY_ORDERER_PHONE_NUMBER]);
                                    setModalUserInfo(undefined);
                                }}>비회원으로 계속하기</FullButton>
                            </>
                    }
                </div>
            </Modal>
            <span className={styles.title}>{title || "주문자 정보"}</span>
            <>
                <div className={styles.inputContainer}>
                    <span className={styles.inputLabel}>이름</span>
                    <div className={styles.inputWrapper}>
                        <TextInput
                            disabled={user && user.rrn}
                                   readonly={isLoading}
                                   onEnter={e => findNextInputAndFocus(e.target)}
                                   error={errors[KEY_ORDERER_NAME] && touched[KEY_ORDERER_NAME]}
                                   placeholder="이름을 입력해주세요" value={values[KEY_ORDERER_NAME]}
                                   maxLength={64}
                                   onChange={(e) => setFieldValue(KEY_ORDERER_NAME, e)}
                        />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <span className={styles.inputLabel}>휴대폰 번호</span>
                    <div className={styles.inputWrapper}>
                        <TextInput
                            pattern={'[0-9]*'}
                            right={isValidPhoneNumber(values[KEY_ORDERER_PHONE_NUMBER]) && !isVerified && isVerificationRequired ? () => {
                                return <div className={styles.resendButton} onClick={() => {
                                    requestNumberVerification(values[KEY_ORDERER_PHONE_NUMBER]);
                                }}>{
                                    !!phoneNumberToSentRef[values[KEY_ORDERER_PHONE_NUMBER]] ? '재전송' : '전송'
                                    }
                                </div>
                            } : undefined}
                            checked={isVerified && isVerificationRequired}
                            disabled={isVerified}
                            ref={phoneNumberInputRef}
                            type="tel" readonly={isLoading} placeholder={
                                !!user && user.rrn && !user.phone_number ?
                                    '변경된 휴대폰 번호를 입력해주세요'
                                    :
                                    '휴대폰 번호를 입력해주세요'
                            }
                            error={errors[KEY_ORDERER_PHONE_NUMBER] && touched[KEY_ORDERER_PHONE_NUMBER]}
                            maxLength={13} value={values[KEY_ORDERER_PHONE_NUMBER]}
                            onChange={e => {
                                if (PHONE_NUMBER_REGEX.test(e) || !e) {
                                    setFieldValue(KEY_ORDERER_PHONE_NUMBER, e);
                                    if (!isVerificationRequired) {
                                        setFieldValue(KEY_VERIFIED_PHONE_NUMBER, e);
                                    }
                                    if (isValidPhoneNumber(e)) {
                                        setTimeout(() => {
                                            phoneNumberInputRef.current.blur();
                                            setTimeout(() => {
                                                findNextInputAndFocus(phoneNumberInputRef.current);
                                            }, 100);
                                            if (isVerificationRequired) {
                                                requestNumberVerification(e);
                                            }
                                        }, 100);
                                    } else {
                                        if (isVerificationRequired) {
                                            setFieldValue(KEY_VERIFIED_PHONE_NUMBER, '');
                                            setVerificationNumber('');
                                        }
                                    }
                                }
                            }}/>
                    </div>
                    {
                        !isVerified && isVerificationRequired?
                            <>
                                <div style={{height: 8}} />
                                <div className={styles.inputWrapper}>
                                    <TextInput
                                        ref={codeInputRef}
                                        right={!!verificationNumber ? () => {
                                            return <CloseCircle
                                                onClick={() => setVerificationNumber('')}
                                                className={classNames(utilStyles.cursorPointer)}
                                                viewBox="0 0 20 20" style={{marginRight: 4}}
                                            />;
                                        } : undefined}
                                        pattern={'[0-9]*'}
                                        // autoComplete="one-time-code"
                                        disabled={!isValidPhoneNumber(values[KEY_ORDERER_PHONE_NUMBER])}
                                        type="number" placeholder={'인증번호를 입력해주세요'}
                                        maxLength={6} value={verificationNumber} onChange={e => {
                                            setVerificationNumber(e);
                                            if (e.length === 6 && /^\d+$/.test(e)) {
                                                confirmNumberVerification(e);
                                            }
                                    }   }/>
                                </div>
                            </>
                            :
                            (
                                isVerified ?
                                    <span className={styles.changeNumber} onClick={() => {
                                        setFieldValue(KEY_ORDERER_PHONE_NUMBER, '');
                                        setFieldValue(KEY_VERIFIED_PHONE_NUMBER, '');
                                        setVerificationNumber('');
                                        if (phoneNumberInputRef.current) {
                                            setTimeout(() => {
                                                phoneNumberInputRef.current.focus();
                                            }, 0);
                                        }
                                    }}>
                                        다른 번호로 변경할래요
                                    </span>
                                    :
                                    <></>
                            )
                    }
                </div>
            </>
            {/*{*/}
            {/*    !user ?*/}
            {/*        <>*/}
            {/*            <div className={styles.inputContainer}>*/}
            {/*                <span className={styles.inputLabel}>주문하시는 분</span>*/}
            {/*                <div className={styles.inputWrapper}>*/}
            {/*                    <TextInput readonly={isLoading}*/}
            {/*                               error={errors[KEY_ORDERER_NAME] && touched[KEY_ORDERER_NAME]}*/}
            {/*                               placeholder="이름을 입력해주세요 (최대 10자)" value={values[KEY_ORDERER_NAME]}*/}
            {/*                               maxLength={10}*/}
            {/*                               onChange={(e) => setFieldValue(KEY_ORDERER_NAME, e)}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className={styles.inputContainer}>*/}
            {/*                <span className={styles.inputLabel}>휴대전화</span>*/}
            {/*                <div className={styles.inputWrapper}>*/}
            {/*                    <TextInput type="tel" readonly={isLoading} placeholder={'010-0000-0000'}*/}
            {/*                               error={errors[KEY_ORDERER_PHONE_NUMBER] && touched[KEY_ORDERER_PHONE_NUMBER]}*/}
            {/*                               maxLength={13} value={values[KEY_ORDERER_PHONE_NUMBER]} onChange={e => {*/}
            {/*                        if (PHONE_NUMBER_REGEX.test(e) || e === '') {*/}
            {/*                            setFieldValue(KEY_ORDERER_PHONE_NUMBER, e);*/}
            {/*                        }*/}
            {/*                    }}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </>*/}
            {/*        :*/}
            {/*        <div>*/}
            {/*            {*/}
            {/*                !!userContext.user.name && !!userContext.user.phone_number ?*/}
            {/*                    <div>*/}
            {/*                        <span className={styles.ordererInfo}>{userContext.user.name}</span>*/}
            {/*                        <span*/}
            {/*                            className={styles.ordererInfo}>{dashedPhoneNumber(userContext.user.phone_number)}</span>*/}
            {/*                    </div>*/}
            {/*                    :*/}
            {/*                    <>*/}
            {/*                        <span className={styles.selfVerificationHelperText}>정확한 주문자 정보 확인을 위해<br/> 주문 시 최초 1회 본인 인증이 필요합니다.</span>*/}
            {/*                        <div className={styles.selfVerificationButtonWrapper}>*/}
            {/*                            <SelfVerificationButton isLoading={isLoading} setIsLoading={setIsLoading}>*/}
            {/*                                <FullButton fontSize={15} title="본인인증 하기" height={44}/>*/}
            {/*                            </SelfVerificationButton>*/}
            {/*                        </div>*/}
            {/*                    </>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*}*/}
        </div>
    )

};


export default CheckoutUserInfoSection;
