import KRCheckoutAgreementSection from "components/sections/checkout/agreement/KRCheckoutAgreementSection";
import USCheckoutAgreementSection from "components/sections/checkout/agreement/USCheckoutAgreementSection";
import {KOREA_COUNTRY_CODE} from "common/i18n";


const CheckoutAgreementSection = (props) => {
    const {countryCode} = props;

    if (countryCode === KOREA_COUNTRY_CODE) {
        return <KRCheckoutAgreementSection {...props} />;
    } else {
        return <USCheckoutAgreementSection {...props} />;
    }
};

export default CheckoutAgreementSection;
