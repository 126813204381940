import KrIcon from "images/icons/country/krIcon.svg"

const KOREA_BASE_URL = process.env.KR_DOMAIN;
const US_BASE_URL = process.env.US_DOMAIN;

const countryChoicesConstants = {
    "Korea": {
        "language": "ko",
        "countryCode": "KR",
        "phoneCountryCode": "+082",
        "currency": '₩',
        "description": 'Korea',
        'baseURL': KOREA_BASE_URL,
    },
    "United States": {
        "language": "en",
        "countryCode": "US",
        "phoneCountryCode": "+001",
        "currency": '$',
        'description': 'United States',
        'baseURL': US_BASE_URL,
    },
    "United Kingdom": {
        "language": "en",
        "countryCode": "GB",
        "phoneCountryCode": "+044",
        "currency": '$',
        'description': 'United Kingdom',
        'baseURL': US_BASE_URL,
    },
    "Indonesia": {
        "language": "id",
        "countryCode": "ID",
        "phoneCountryCode": "+062",
        "currency": "$",
        "description": "Indonesia",
        "baseURL": US_BASE_URL,
    },
    // "Japan": {
    //     "language": "ja",
    //     "countryCode": "JP",
    //     "phoneCountryCode": "+081",
    //     "currency": "$",
    //     "description": "Japan",
    //     "baseURL": US_BASE_URL,
    // },
    // "Hong Kong": {
    //     "language": "zh-HK",
    //     "countryCode": "HK",
    //     "phoneCountryCode": "+852",
    //     "currency": "$",
    //     "description": "Hong Kong",
    //     "baseURL": US_BASE_URL,
    // },
    "Philippines": {
        "language": "fil",
        "countryCode": "PH",
        "phoneCountryCode": "+063",
        "currency": "$",
        "description": "Philippines",
        "baseURL": US_BASE_URL,
    },
    "Singapore": {
        "language": "en",
        "countryCode": "SG",
        "phoneCountryCode": "+065",
        "currency": "$",
        "description": "Singapore",
        "baseURL": US_BASE_URL,
    },
    "Malaysia": {
        "language": "ms",
        "countryCode": "MY",
        "phoneCountryCode": "+060",
        "currency": "$",
        "description": "Malaysia",
        "baseURL": US_BASE_URL,
    },
    "Taiwan": {
        "language": "zh-TW",
        "countryCode": "TW",
        "phoneCountryCode": "+886",
        "currency": "$",
        "description": "Taiwan",
        "baseURL": US_BASE_URL,
    },
    "Vietnam": {
        "language": "vi",
        "countryCode": "VN",
        "phoneCountryCode": "+084",
        "currency": "$",
        "description": "Vietnam",
        "baseURL": US_BASE_URL,
    },
    "Australia": {
        "language": "en",
        "countryCode": "AU",
        "phoneCountryCode": "+061",
        "currency": "$",
        "description": "Australia",
        "baseURL": US_BASE_URL,
    },
    "Germany": {
        "language": "de",
        "countryCode": "DE",
        "phoneCountryCode": "+049",
        "currency": "$",
        "description": "Germany",
        "baseURL": US_BASE_URL,
    },
    "France": {
        "language": "fr",
        "countryCode": "FR",
        "phoneCountryCode": "+033",
        "currency": "$",
        "description": "France",
        "baseURL": US_BASE_URL,
    },
    "Italy": {
        "language": "it",
        "countryCode": "IT",
        "phoneCountryCode": "+039",
        "currency": "$",
        "description": "Italy",
        "baseURL": US_BASE_URL,
    },
    "Spain": {
        "language": "es",
        "countryCode": "ES",
        "phoneCountryCode": "+034",
        "currency": "$",
        "description": "Spain",
        "baseURL": US_BASE_URL,
    },
    "Poland": {
        "language": "pl",
        "countryCode": "PL",
        "phoneCountryCode": "+048",
        "currency": "$",
        "description": "Poland",
        "baseURL": US_BASE_URL,
    },
    "Netherlands": {
        "language": "nl",
        "countryCode": "NL",
        "phoneCountryCode": "+031",
        "currency": "$",
        "description": "Netherlands",
        "baseURL": US_BASE_URL,
    },
    "Portugal": {
        "language": "pt",
        "countryCode": "PT",
        "phoneCountryCode": "+351",
        "currency": "$",
        "description": "Portugal",
        "baseURL": US_BASE_URL,
    },
    "Ukraine": {
        "language": "uk",
        "countryCode": "UA",
        "phoneCountryCode": "+380",
        "currency": "$",
        "description": "Ukraine",
        "baseURL": US_BASE_URL,
    },
    "Uzbekistan": {
        "language": "uz",
        "countryCode": "UZ",
        "phoneCountryCode": "+998",
        "currency": "$",
        "description": "Uzbekistan",
        "baseURL": US_BASE_URL,
    },
    "Saudi Arabia": {
        "language": "ar",
        "countryCode": "SA",
        "phoneCountryCode": "+966",
        "currency": "$",
        "description": "Saudi Arabia",
        "baseURL": US_BASE_URL,
    },
    "Qatar": {
        "language": "ar",
        "countryCode": "QA",
        "phoneCountryCode": "+974",
        "currency": "$",
        "description": "Qatar",
        "baseURL": US_BASE_URL,
    },
    "Oman": {
        "language": "ar",
        "countryCode": "OM",
        "phoneCountryCode": "+968",
        "currency": "$",
        "description": "Oman",
        "baseURL": US_BASE_URL,
    },
    "Kuwait": {
        "language": "ar",
        "countryCode": "KW",
        "phoneCountryCode": "+965",
        "currency": "$",
        "description": "Kuwait",
        "baseURL": US_BASE_URL,
    },
    "Bahrain": {
        "language": "ar",
        "countryCode": "BH",
        "phoneCountryCode": "+973",
        "currency": "$",
        "description": "Bahrain",
        "baseURL": US_BASE_URL,
    },
    "United Arab Emirates": {
        "language": "ar",
        "countryCode": "AE",
        "phoneCountryCode": "+971",
        "currency": "$",
        "description": "United Arab Emirates",
        "baseURL": US_BASE_URL,
    },
    "Romania": {
        "language": "ro",
        "countryCode": "RO",
        "phoneCountryCode": "+040",
        "currency": "$",
        "description": "Romania",
        "baseURL": US_BASE_URL,
    },
    "Sweden": {
        "language": "sv",
        "countryCode": "SE",
        "phoneCountryCode": "+046",
        "currency": "$",
        "description": "Sweden",
        "baseURL": US_BASE_URL,
    },
    "Norway": {
        "language": "no",
        "countryCode": "NO",
        "phoneCountryCode": "+047",
        "currency": "$",
        "description": "Norway",
        "baseURL": US_BASE_URL,
    },
    "Belarus": {
        "language": "be",
        "countryCode": "BY",
        "phoneCountryCode": "+375",
        "currency": "$",
        "description": "Belarus",
        "baseURL": US_BASE_URL,
    },
    "Austria": {
        "language": "de",
        "countryCode": "AT",
        "phoneCountryCode": "+043",
        "currency": "$",
        "description": "Austria",
        "baseURL": US_BASE_URL,
    },
    "Belgium": {
        "language": "nl",
        "countryCode": "BE",
        "phoneCountryCode": "+032",
        "currency": "$",
        "description": "Belgium",
        "baseURL": US_BASE_URL,
    },
    "Czech Republic": {
        "language": "cs",
        "countryCode": "CZ",
        "phoneCountryCode": "+420",
        "currency": "$",
        "description": "Czech Republic",
        "baseURL": US_BASE_URL,
    },
    "Hungary": {
        "language": "hu",
        "countryCode": "HU",
        "phoneCountryCode": "+036",
        "currency": "$",
        "description": "Hungary",
        "baseURL": US_BASE_URL,
    },
    "Ireland": {
        "language": "en",
        "countryCode": "IE",
        "phoneCountryCode": "+353",
        "currency": "$",
        "description": "Ireland",
        "baseURL": US_BASE_URL,
    },
    "Serbia": {
        "language": "sr",
        "countryCode": "RS",
        "phoneCountryCode": "+381",
        "currency": "$",
        "description": "Serbia",
        "baseURL": US_BASE_URL,
    },
    "Croatia": {
        "language": "hr",
        "countryCode": "HR",
        "phoneCountryCode": "+385",
        "currency": "$",
        "description": "Croatia",
        "baseURL": US_BASE_URL,
    },
    "Georgia": {
        "language": "ka",
        "countryCode": "GE",
        "phoneCountryCode": "+995",
        "currency": "$",
        "description": "Georgia",
        "baseURL": US_BASE_URL,
    },
    "Bosnia and Herzegovina": {
        "language": "bs",
        "countryCode": "BA",
        "phoneCountryCode": "+387",
        "currency": "$",
        "description": "Bosnia and Herzegovina",
        "baseURL": US_BASE_URL,
    },
    "Montenegro": {
        "language": "me",
        "countryCode": "ME",
        "phoneCountryCode": "+382",
        "currency": "$",
        "description": "Montenegro",
        "baseURL": US_BASE_URL,
    },
    "Kenya": {
        "language": "en",
        "countryCode": "KE",
        "phoneCountryCode": "+254",
        "currency": "$",
        "description": "Kenya",
        "baseURL": US_BASE_URL,
    },
};

export const countryChoices = Object.keys(countryChoicesConstants)
    .sort()
    .reduce((acc, key) => {
        acc[key] = countryChoicesConstants[key];
        return acc;
    }, {});


const baseURLToCountryCodes = Object.values(countryChoices).reduce(
    (acc, {baseURL, countryCode}) => {
        acc[baseURL] = acc[baseURL] ? [...acc[baseURL], countryCode] : [countryCode];
        return acc;
    }, {});

export const COUNTRY_CODE_TO_COUNTRY_INFO = Object.keys(countryChoices).reduce((acc, countryName) => {
    const countryData = countryChoices[countryName];
    acc[countryData.countryCode] = countryData;
    return acc;
}, {});

export const COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES = Object.entries(baseURLToCountryCodes).reduce(
    (acc, [url, countryCodes]) => {
        countryCodes.forEach(
            countryCode => acc[countryCode] = countryCodes
        );

        return acc;
    }, {}
);

export const COUNTRY_CODE_TO_BASE_URL = Object.entries(baseURLToCountryCodes).reduce(
    (acc, [url, countryCodes]) => {
        countryCodes.forEach(code => {
            acc[code] = url;
        });
        return acc;
    }, {}
);

export const getPhoneCountryCode = (countryCode) => {
    return COUNTRY_CODE_TO_COUNTRY_INFO[countryCode].phoneCountryCode || COUNTRY_CODE_TO_COUNTRY_INFO[COUNTRY_CODE].phoneCountryCode;
}

export const countryCodeToChoice = Object.values(countryChoices).reduce(
    (acc, {countryCode, ...rest}) => {
        acc[countryCode] = {
            ...rest,
            countryName: Object.keys(countryChoices).find(key => countryChoices[key].countryCode === countryCode),
            countryCode: countryCode,
        };
        return acc;
    }, {}
);

export function getDescriptionByCountryCode(code) {
    for (const [country, info] of Object.entries(countryChoices)) {
        if (info.countryCode === code) {
            return info.description;
        }
    }
    return null;
}

export function getCountryByCode(code) {
    for (const [country, info] of Object.entries(countryChoices)) {
        if (info.countryCode === code) {
            return country;
        }
    }
    return null;
}

export function getPhoneCodeByCode(code) {
    for (const [country, info] of Object.entries(countryChoices)) {
        if (info.countryCode === code) {
            return info.phoneCountryCode;
        }
    }
}

export function getStateCodeFromFullName(stateName) {
    const states = {
        "Alabama": "AL",
        "Alaska": "AK",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "Florida": "FL",
        "Georgia": "GA",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Pennsylvania": "PA",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY"
    };

    return states[stateName] || null;
}
