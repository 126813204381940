import styles from "components/sections/checkout/agreement/CheckoutAgreementSection.module.scss";
import UncheckedCheckBoxIcon from "images/icons/checkbox_unchecked.svg";
import CheckedCheckBoxIcon from "images/icons/checkbox_checked.svg";
import Link from "next/link";
import {privacyPolicyRoute, termsOfServiceRoute} from "common/const";
import {KEY_AGREEMENT_CHECKED} from "pages/store/orders/[ordNum]/checkout";


const USCheckoutAgreementSection = (props) => {
    const {formik, isPaypalLoaded} = props;

    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;

    const onAgreeAllClick = () => {
        if (isPaypalLoaded) {
            return;
        }
        setFieldValue(KEY_AGREEMENT_CHECKED, !values[KEY_AGREEMENT_CHECKED]);
    };

    const onShowDetailTextClick = (type) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageShowTermsDetailTextClick', {type: type});
    };

    return (
        <div className={styles.agreementContainer}>
            <div onClick={onAgreeAllClick} className={styles.agreeAllContainer}>
                {
                    !values[KEY_AGREEMENT_CHECKED] ?
                        <UncheckedCheckBoxIcon className={styles.checkbox}/> :
                        <CheckedCheckBoxIcon className={styles.checkbox}/>
                }
                <div className={styles.agreeTextContainer}>
                    <span className={styles.agree}>
                        I accept the
                    </span>
                    <Link href={termsOfServiceRoute}>
                        <a target="_blank" className={styles.agreeSeeMore}
                           onClick={() => onShowDetailTextClick('Terms of Service')}
                        >
                            Terms of Service
                        </a>
                    </Link>
                    <span className={styles.agree}>
                        and
                    </span>
                    <Link href={privacyPolicyRoute}>
                        <a target="_blank" className={styles.agreeSeeMore}
                           onClick={() => onShowDetailTextClick('Privacy Policy')}
                        >
                            Privacy Policy
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default USCheckoutAgreementSection;
