const priceToUSD = (price) => {
    let txt = price.toString();
    if (txt.length <= 2) {
        txt = '0.' + txt;
    } else {
        txt = txt.slice(0, txt.length - 2) + '.' + txt.slice(txt.length - 2);
    }

    return parseFloat(txt);
};

export const priceToCurrencyPrice = (price, currency) => {
    if (currency === 'KRW') {
        return price;
    } else if (currency === 'USD') {
        return priceToUSD(price);
    } else {
        return price;
    }
};
