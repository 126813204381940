import KRCheckoutInfo from "components/sections/checkout/info/KRCheckoutInfo";
import USCheckoutInfo from "components/sections/checkout/info/USCheckoutInfo";
import {toast} from "react-toastify";
import {useRouter} from "next/router";
import {pouchRoute} from "common/const";

const CheckoutInfo = (props) => {
    const {order} = props;
    const router = useRouter();
    if (!order) {
        toast.info('Failed to load order information. Redirecting to pouch.');
        setTimeout(() => {
            router.push(pouchRoute);
        }, 3000);
        return <div/>;
    }
    if (!order.order) {
        return <div/>;
    }
    if (order.order.country_code === 'KR') {
        return <KRCheckoutInfo {...props} />;
    } else {
        return <USCheckoutInfo {...props} />;
    }
}

export default CheckoutInfo
