import styles from "components/cards/checkoutCouponCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import {formattedPrice} from "common/utils";
import classNames from "classnames";
import useTimer from "hooks/useTimer";
import {useMemo} from "react";
import useTranslation from "next-translate/useTranslation";
import CheckIcon from "images/icons/check_grey.svg";
import DownloadIcon from "images/icons/download.svg";
import {getConditionText, handleCouponDownload} from "components/cards/coupon/DownloadableCouponCard";
import {COUNTRY_CODE} from "common/const";


export const COUPON_TYPE_PERCENTAGE = 'PTG';
export const COUPON_TYPE_FIXED = 'FIX';

export default function DownloadableCouponCardV2(props) {
    const {
        couponMold, onDownloadSuccess, hasDownloaded, isLoading, setIsLoading, withBorder,
        unselectable, relative, expired, showTotal, user, router
    } = props;
    const onSelect = props.onSelect || function () {
    };

    const {t} = useTranslation('components-cards-coupon-DownloadableCouponCardV2');

    const currency = useMemo(() => {
        return couponMold?.currency;
    }, [couponMold?.currency])

    const currencyText = useMemo(() => {
        return t(`common:currency.${couponMold?.currency}`);
    }, [couponMold?.currency]);

    const amount = relative ? couponMold.amount : couponMold.discount;
    const unit = relative ? (couponMold.type === COUPON_TYPE_PERCENTAGE ? '%' : currencyText) : currencyText;

    const {timeString, seconds} = useTimer(couponMold.usable_after);

    const couponDiscountValueTitle = () => {
        if (couponMold.type === COUPON_TYPE_FIXED) {
            return formattedPrice(amount, currency);
        }
        return amount;
    };

    const _handleCouponDownload = () => {
        handleCouponDownload(couponMold, isLoading, setIsLoading, onDownloadSuccess, router, user, COUNTRY_CODE, t);
    };

    const {couponAmount, couponExpiresAt, condition} = getConditionText(couponMold, t, COUNTRY_CODE);


    return (
        <div className={classNames(styles.downloadCouponContainer)}
             onClick={_handleCouponDownload}>
            <div className={styles.infoContainer}>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, (
                    couponMold.is_app_only || couponMold.is_gift_only || couponMold.is_double_discount || (!unselectable && timeString) || !!couponMold.is_over_people_count
                ) ? styles.tagsContainer : undefined)}>
                    {
                        couponMold.is_app_only &&
                        <div className={styles.appOnlyLabel}>
                            {t('CheckoutCouponCard.appOnly')}
                        </div>
                    }
                    {
                        couponMold.is_gift_only &&
                        <div className={styles.giftOnlyLabel}>
                            {t('CheckoutCouponCard.giftOnly')}
                        </div>
                    }
                    {
                        couponMold.is_double_discount &&
                        <div className={styles.doubleDiscountLabel}>
                            {t('CheckoutCouponCard.doubleDiscount')}
                        </div>
                    }
                    {
                        !unselectable && timeString &&
                        <div className={styles.timerLabel}>
                            {t('CheckoutCouponCard.usableAfter', {time: timeString})}
                        </div>
                    }
                    {
                        !!couponMold.is_over_people_count &&
                        <div className={styles.fifoOver}>
                            {t('CheckoutCouponCard.couponDepleted')}
                        </div>
                    }
                </div>
                <div className={styles.discountAmountWrapper}>
                    <span className={classNames(styles.discount, expired ? styles.expiredDiscount : undefined)}>
                        {couponDiscountValueTitle()}{unit}
                        {
                            (couponMold.type === COUPON_TYPE_PERCENTAGE && showTotal && couponMold?.discount > 0) &&
                            <span
                                className={styles.totalDiscount}> ({formattedPrice(couponMold.discount, currency)}{currencyText} {t('CheckoutCouponCard.discount')})</span>
                        }
                    </span>
                </div>
                <span
                    className={classNames(styles.name, expired ? styles.expiredName : undefined)}>{couponMold.name}</span>
                {
                    condition &&
                    <span className={classNames(styles.conditionText, expired ? styles.expiredName : undefined)}>
                        {condition}
                    </span>
                }
                <span className={classNames(styles.period, expired ? styles.expiredPeriod : undefined)}>
                    {couponExpiresAt}
                </span>
            </div>
            <div className={classNames(styles.downloadButton)}>
                {
                    hasDownloaded ?
                        <CheckIcon viewBox="0 0 16 16" className={styles.checkIcon}/> :
                        <DownloadIcon viewBox="0 0 19 18"
                                      className={classNames(styles.downloadIcon)}/>
                }
            </div>
        </div>
    )
}
