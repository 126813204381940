import styles from 'components/modal/deliveryAddressModal.module.scss';
import utilStyles from 'styles/utils.module.scss';
import classNames from "classnames";

import CheckedCheckboxIcon from "images/icons/checkbox_checked.svg";
import UncheckedCheckboxIcon from "images/icons/checkbox_unchecked.svg";
import PlusIcon from "images/icons/plus_brand.svg";
import {useContext, useRef, useState} from "react";
import UserContext from "context/AuthContext";
import {useFormik} from "formik";
import InputLabel from "components/input/InputLabel";
import TextInput from "components/input/TextInput";
import FullButton from "components/buttons/FullButton";
import DaumPostcode from "react-daum-postcode";
import {Axios} from "api";
import * as yup from 'yup';
import TitleBar from "components/layout/TitleBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {findNextInputAndFocus, getLocalStorageCountryCode, isEmptyDictionary} from "common/utils";
import {deliveryMemoChoices, VALUE_SELF_ENTER} from "pages/store/orders/[ordNum]/checkout";
import checkoutStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import TextInputDropdown from "components/input/TextInputDropdown";
import {captureException} from "@sentry/nextjs";
import CountryInputSelector from "components/input/CountryInputSelector";
import {COUNTRY_CODE_TO_COUNTRY_INFO, countryChoices, getCountryByCode, getPhoneCountryCode} from "common/country_list";
import InputError from "components/input/InputError";
import {DeliveryInstructionComponent, onZipcodeApply} from "components/cards/CheckoutAddressCardGlobal";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";


export const AddressCard = (props) => {
    const { t } = useTranslation('components-modal-DeliveryAddressModal');
    const {address, defaultAddressId, isLoading, selectDisabled, isDesktop} = props;
    const onEdit = props.onEdit || function () {};
    const close = props.close || function () {};
    const userContext = useContext(UserContext);
    const onChangeAddress = props.onChangeAddress || function () {};
    const setIsLoading = props.setIsLoading || function () {};
    const countryCode = getLocalStorageCountryCode()?? 'KR';

    const deleteAddress = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('AddressCardDeleteAddressClick');
        setIsLoading(true);
        try {
            const res = await Axios.delete(`v1/user/addresses/${address.id}/`);
            if (res.status < 400) {
                userContext.deleteAddress(address.id);
            } else {
                alert(t('AddressCard.deleteError'));
            }
        } catch (e) {
            alert(t('AddressCard.deleteError'));
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <div className={styles.cardContainer}>
            {
                !selectDisabled &&
                <div className={styles.selectButton}>
                    <FullButton height={30} onClick={() => {
                        onChangeAddress(address);
                        close();
                    }} disabled={isLoading} fontSize={14} title={t('AddressCard.select')} />
                </div>
            }
            <div className={classNames(styles.infoContainer, isDesktop ? styles.infoContainerDesktop : undefined)}>
                <div className={styles.nameContainer}>
                    <div className={styles.defaultAddressContainer}>
                        <span className={styles.addressName}>{address.receiver_name}</span>
                        <span className={classNames(styles.defaultAddressMark, defaultAddressId !== address.id ? utilStyles.invisible : undefined)}>{t('AddressCard.defaultAddress')}</span>
                    </div>
                </div>
                <div className={styles.contactContainer}>
                    {
                        address.country_code !== 'KR' &&
                        <span className={styles.contact}>({address.phone_country_code}) &nbsp;</span>
                    }
                    <span className={styles.contact}>{address.phone_number}</span>
                    <span className={styles.address}>({address.postal_code})</span>
                    <span className={styles.address}
                          style={{marginTop: 4}}>{address.rough_address} {address.detail_address}</span>
                    {
                        address.memo &&
                        <span className={styles.memo}>{address.memo}</span>
                    }
                </div>
            </div>
            <div className={classNames(styles.editContainer)}>
                <span className={styles.actionButton} onClick={isLoading ? undefined : onEdit}>{t('AddressCard.edit')}</span>
                <span className={styles.actionButton} onClick={isLoading ? undefined : deleteAddress}>{t('AddressCard.delete')}</span>
            </div>
        </div>
    )
}


export const PHONE_NUMBER_REGEX = new RegExp('^[-\\d]+$');

export const AddressAdd = (props) => {
    const { t } = useTranslation('components-modal-DeliveryAddressModal');
    const close = props.close || function () {};
    const onClose = props.onClose;
    const {targetAddress, searchOnly} = props;
    const onComplete = props.onComplete || function () {};

    const userContext = useContext(UserContext);

    const isEdit = !!targetAddress;

    const [isSearching, setIsSearching] = useState(!!searchOnly);
    const [isLoading, setIsLoading] = useState(false);

    const request = isEdit ? Axios.put : Axios.post;
    const endpoint = isEdit ? `v1/user/addresses/${targetAddress.id}/` : 'v1/user/addresses/';
    const countryCode = targetAddress ? targetAddress.country_code : getLocalStorageCountryCode()?? 'KR'
    const getInitialValues = () => {
        let obj = {
            phone_number: targetAddress ? targetAddress.phone_number : '',
            phone_country_code: targetAddress ? targetAddress.phone_country_code : countryCode === 'KR' ? '+82': getPhoneCountryCode(countryCode),
            country_code: targetAddress ? targetAddress.country_code : countryCode,
            city: targetAddress ? targetAddress.city : '',
            state: targetAddress ? targetAddress.state : '',
            postal_code: targetAddress ? targetAddress.postal_code : '',
            rough_address: targetAddress ? targetAddress.rough_address : '',
            detail_address: targetAddress ? targetAddress.detail_address : '',
            is_default: targetAddress && userContext.user ? userContext.user.default_address_id === targetAddress.id : false,
            memo: targetAddress ? targetAddress.memo : '',
        }

        if(countryCode === 'KR') {
            return {...obj, ...{receiver_name: targetAddress ? targetAddress.receiver_name : ''}}
        }
        return {
            ...obj,
            ...{
                receiver_last_name: targetAddress ? targetAddress.receiver_name?.split(', ')[0] : '',
                receiver_first_name: targetAddress ? targetAddress.receiver_name?.split(', ')[1] : '',
                email: targetAddress ? targetAddress.email : '',
            }
        }
    }
    const getValidationSchema = () => {
        let schema = {
            phone_number: yup.string().required().max(13),
            phone_country_code: yup.string().required().max(4),
            postal_code: yup.string().required('required').matches(/^\d*$/).min(4).max(6, 'max length of 6 characters'),
            rough_address: yup.string().required(),
            is_default: yup.bool().required(),
            memo: yup.string(),
        };
        if(countryCode === 'KR') {
            return yup.object({
                ...schema,
                ...{
                    receiver_name: yup.string().required().max(10),
                    detail_address: yup.string().max(40).required()
                }
            })
        }
        return yup.object({
            ...schema,
            ...{
                receiver_last_name: yup.string().required().max(15),
                receiver_first_name: yup.string().required().max(15),
                city: yup.string().max(40),
                state: yup.string().max(40),
                detail_address: yup.string().max(50),
                email: yup.string().required().email()
            }
        })
    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: getInitialValues(),
        validationSchema: getValidationSchema(),
        onSubmit: async values => {
            setIsLoading(true);
            try {
                const res = await request(endpoint, values);
                if (res.status < 400) {
                    userContext.addOrUpdateAddress(res.data.address, res.data.default_address_id);
                    close();
                } else {
                    if (res.data.display_message) {
                        alert(res.data.display_message);
                        close();
                    } else {
                        alert(t('AddressCard.deleteError'));
                    }
                }
            } catch (e) {
                captureException(e);
                alert(t('AddressCard.deleteError'));
            } finally {
                setIsLoading(false);
            }
        },
    })

    const {values, setFieldValue, setValues, errors, handleSubmit, touched} = formik;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);


    const [selfEnter, setSelfEnter] = useState(!deliveryMemoChoices.includes(values.memo) && values.memo !== '');
    const detailAddressInputRef = useRef();
    const onDeliveryMemoChoiceClick = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageDeliveryMemoChoiceClick', {choice: e});
        setFieldValue('memo', e);
        if (e === VALUE_SELF_ENTER) {
            setSelfEnter(true);
            setFieldValue('memo', '');
        } else {
            setFieldValue('memo', e);
            setSelfEnter(false);
        }
    };
    const onCountryChoiceClick = (e) => {
        setFieldValue('country_code', COUNTRY_CODE_TO_COUNTRY_INFO[e]?.countryCode);
        setFieldValue('phone_country_code', COUNTRY_CODE_TO_COUNTRY_INFO[e]?.phoneCountryCode);
    };

    console.log(values, 'values@@');

    return (
        <div className={classNames(utilStyles.flexCol, isMobile ? utilStyles.justifyContentSpaceBetween : undefined)} style={{height: '100%'}}>
            {
                isSearching && countryCode === 'KR'?
                    <div>
                        <div className={classNames(utilStyles.topSticky, utilStyles.whiteBackground)}>
                            <TitleBar title={t('AddressAdd.searchAddress')} close={onClose ? () => onClose() : () => setIsSearching(false)} isBack={props.isBack} isClose={props.isClose} />
                        </div>
                        <div style={{height: 24}} />
                        <DaumPostcode style={{width: '100%', height: 600}} autoClose={false} onComplete={
                            searchOnly ?
                            (res) => {
                                onComplete(res);
                                close();
                            }
                            :
                            (res) => {
                                setValues((values) => {
                                    const newValues = JSON.parse(JSON.stringify(values));
                                    newValues.rough_address = res.roadAddress;
                                    newValues.postal_code = res.zonecode;
                                    return newValues;
                                });
                                setTimeout(() => {
                                    if (detailAddressInputRef.current) {
                                        detailAddressInputRef.current.focus();
                                    }
                                }, 0);
                                setIsSearching(false);
                            }
                        } />
                    </div>
                    :
                    <>
                        <div>
                            <div className={classNames(utilStyles.topSticky, utilStyles.whiteBackground)}>
                                <TitleBar title={isEdit ? t('AddressAdd.editAddress') : t('AddressAdd.addAddress')} close={close} isClose={props.isClose}
                                          isBack={props.isBack}/>
                            </div>
                            <div style={{height: 20}}/>
                            {
                                countryCode !== 'KR' &&
                                <>
                                    <InputLabel title={t('AddressAdd.country')}/>
                                    <CountryInputSelector
                                        placeholder={t('AddressAdd.selectCountry')}
                                        onChange={onCountryChoiceClick}
                                        countryCode={values.country_code}
                                        onlyPossibleCountries
                                    />
                                    <DeliveryInstructionComponent countryCode={values.country_code}/>
                                </>
                            }
                            <div style={{height: 20}}/>
                            <InputLabel title={t('AddressAdd.recipient')}/>
                            {
                                countryCode === 'KR' ?
                                    <TextInput
                                        onEnter={e => findNextInputAndFocus(e.target)}
                                        readonly={isLoading} error={errors.receiver_name && touched.receiver_name}
                                        maxLength={15} placeholder={t('AddressAdd.enterName')} value={values.receiver_name}
                                        onChange={e => setFieldValue('receiver_name', e)}/>
                                    :
                                    <div>
                                        <TextInput
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            readonly={isLoading}
                                            error={errors.receiver_first_name && touched.receiver_first_name}
                                            maxLength={15} placeholder={t('AddressAdd.enterFirstName')} value={values.receiver_first_name}
                                            onChange={e => setFieldValue('receiver_first_name', e)}/>
                                        <div style={{height: 10}}/>
                                        <TextInput
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            readonly={isLoading}
                                            error={errors.receiver_last_name && touched.receiver_last_name}
                                            maxLength={15} placeholder={t('AddressAdd.enterLastName')} value={values.receiver_last_name}
                                            onChange={e => setFieldValue('receiver_last_name', e)}/>
                                        <div style={{height: 10}}/>
                                        <TextInput
                                            type="email"
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            error={errors.email && touched.email}
                                            placeholder={t('AddressAdd.enterEmail')} value={values.email}
                                            maxLength={64}
                                            onChange={(e) => setFieldValue('email', e)}
                                        />
                                        {
                                            (errors.email && touched.email) &&
                                            <InputError message={errors.email}/>
                                        }
                                    </div>

                            }

                            <div style={{height: 20}}/>
                            <InputLabel title={t('AddressAdd.phoneNumber')}/>
                            <div className={styles.flexRow}>
                                {
                                    countryCode !== 'KR' &&
                                    <div style={{width: '30%', marginRight: 6}}>
                                        <TextInput
                                            disabled={false}
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            onBlur={e => findNextInputAndFocus(e.target)}
                                            type={'text'}
                                            error={errors.phone_country_code && touched.phone_country_code}
                                            placeholder={values.phone_country_code?? '+1'}
                                            value={values.phone_country_code}
                                            maxLength={4}
                                            onChange={e => {setFieldValue('phone_country_code', e || '')}}
                                        />
                                    </div>
                                }
                                <TextInput
                                    onEnter={e => findNextInputAndFocus(e.target)}
                                    onBlur={e => findNextInputAndFocus(e.target)}
                                    type={'tel'} readonly={isLoading} error={errors.phone_number && touched.phone_number}
                                    placeholder={t('AddressAdd.enterPhoneNumber')} maxLength={13} value={values.phone_number}
                                    onChange={e => {
                                        if (PHONE_NUMBER_REGEX.test(e) || !e) {
                                            setFieldValue('phone_number', e || '');
                                        }
                                    }}/>
                        </div>
                            <div style={{height: 20}}/>
                            <InputLabel title={t('AddressAdd.address')}/>
                            {
                                countryCode === 'KR' ?
                                    <>
                                        <div className={styles.flexRow}
                                             onClick={() => !isLoading && setIsSearching(true)}>
                                            <div style={{flex: 1}}><TextInput
                                                error={errors.postal_code && touched.postal_code}
                                                disabled placeholder={t('AddressAdd.enterAddress')}
                                                value={values.postal_code}/></div>
                                            <div className={styles.findAddressButton}>{t('AddressAdd.findAddress')}</div>
                                        </div>


                                        {
                                            values.postal_code &&
                                            <>
                                                <div style={{height: 8}}/>
                                                <div onClick={() => !isLoading && setIsSearching(true)}>
                                                    <TextInput error={errors.rough_address && touched.rough_address}
                                                               disabled
                                                               value={values.rough_address}/>
                                                </div>
                                                <div style={{height: 8}}/>
                                                <TextInput ref={detailAddressInputRef} readonly={isLoading}
                                                           error={errors.detail_address && touched.detail_address}
                                                           value={values.detail_address}
                                                           placeholder={t('AddressAdd.enterDetailAddress')}
                                                           onChange={e => setFieldValue('detail_address', e)}/>
                                                <div className={classNames(styles.memoContainer)}>
                                                    <div className={checkoutStyles.inputWrapper}>
                                                        <TextInputDropdown
                                                            placeholder={t('AddressAdd.selectDeliveryRequest')}
                                                            choices={deliveryMemoChoices.concat([VALUE_SELF_ENTER])}
                                                            onChange={onDeliveryMemoChoiceClick}
                                                            value={selfEnter ? VALUE_SELF_ENTER : values.memo}/>
                                                    </div>
                                                </div>
                                                {
                                                    selfEnter &&
                                                    <>
                                                        <div style={{height: 8}}/>
                                                        <TextInput placeholder={t('AddressAdd.enterDeliveryRequest')} maxLength={50}
                                                                   value={values.memo}
                                                                   onChange={(e) => setFieldValue('memo', e)}/>
                                                    </>
                                                }
                                            </>
                                        }
                                    </> :
                                    <>
                                        <div className={styles.flexRow}
                                             onClick={async () => {
                                                 if(countryCode !== 'US') {
                                                     return;
                                                 }
                                                 const data = await onZipcodeApply(countryCode, values.postal_code)
                                                 await setFieldValue('city', data?.['place name'])
                                                 await setFieldValue('state', data?.['state'])
                                             }}>
                                            <div style={{flex: 1}}>
                                                <TextInput
                                                    type="number"
                                                    onEnter={e => findNextInputAndFocus(e.target)}
                                                    onBlur={e => findNextInputAndFocus(e.target)}
                                                    error={errors.postal_code && touched.postal_code}
                                                    placeholder={t('AddressAdd.enterPostalCode')}
                                                    maxLength={6}
                                                    onChange={e => {
                                                        setFieldValue('postal_code', e || '')
                                                    }}
                                                    value={values.postal_code}
                                                />
                                                {
                                                    (errors.postal_code && touched.postal_code) &&
                                                    <InputError message={errors.postal_code}/>
                                                }
                                            </div>
                                            {
                                                countryCode === 'US' &&
                                                <div className={styles.findAddressButton}>{t('AddressAdd.apply')}</div>

                                            }
                                        </div>
                                        <div style={{height: 20}}/>
                                        <div className={styles.flexRow}>
                                            <div style={{width: '100%', marginRight: 7}}>
                                                <TextInput
                                                    onEnter={e => findNextInputAndFocus(e.target)}
                                                    placeholder={t('AddressAdd.enterCity')} value={values.city}
                                                    maxLength={40}
                                                    onChange={(e) => setFieldValue('city', e)}
                                                />
                                            </div>
                                            <TextInput
                                                onEnter={e => findNextInputAndFocus(e.target)}
                                                placeholder={t('AddressAdd.enterState')} value={values.state}
                                                maxLength={40}
                                                onChange={(e) => setFieldValue('state', e)}
                                            />
                                        </div>
                                        <div style={{height: 10}}/>
                                        <TextInput
                                            disabled={false}
                                            readonly={isLoading}
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            error={errors.rough_address && touched.rough_address}
                                            placeholder={t('AddressAdd.enterAddressLine1')} value={values.rough_address}
                                            maxLength={50}
                                            onChange={(e) => setFieldValue('rough_address', e)}
                                        />
                                        {
                                            (errors.rough_address && touched.rough_address) &&
                                            <InputError message={errors.rough_address}/>
                                        }
                                        <div style={{height: 10}}/>
                                        <TextInput
                                            disabled={false}
                                            readonly={isLoading}
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            error={errors.detail_address && touched.detail_address}
                                            placeholder={t('AddressAdd.enterAddressLine2')} value={values.detail_address}
                                            maxLength={50}
                                            onChange={(e) => setFieldValue('detail_address', e)}
                                        />
                                        {
                                            (errors.detail_address && touched.detail_address) &&
                                            <InputError message={errors.detail_address}/>
                                        }
                                    </>
                            }
                            <div style={{height: 20}}/>
                            <div style={{lineHeight: 0}} className={utilStyles.cursorPointer}
                                 onClick={() => !isLoading && setFieldValue('is_default', !values.is_default)}>
                                {
                                    values.is_default ?
                                        <CheckedCheckboxIcon viewBox="0 0 24 24" width="24" height="24"
                                                             className={utilStyles.verticalMiddle}/>
                                        :
                                        <UncheckedCheckboxIcon viewBox="0 0 24 24" width="24" height="24"
                                                               className={utilStyles.verticalMiddle}/>
                                }
                                <span className={styles.defaultAddress}>{t('AddressAdd.saveAsDefault')}</span>
                            </div>
                            <div style={{height: 32}}/>
                        </div>
                        <div className={styles.saveButtonWrapper} style={{padding: '12px 0'}}>
                            <FullButton
                                disabled={isLoading || !(values.receiver_name || (values.receiver_first_name && values.receiver_last_name)) || !isEmptyDictionary(errors)}
                                title={isEdit ? t('AddressAdd.edit') : t('AddressAdd.register')} height={48} fontSize={16} onClick={() => {
                                handleSubmit();
                            }} />
                        </div>
                    </>
            }
        </div>
    )
}


export default function AddressList (props) {
    const { t } = useTranslation('components-modal-DeliveryAddressModal');
    const {defaultScreen} = props;
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const isDefaultAdd = defaultScreen === 'add';
    const [targetAddress, setTargetAddress] = useState(isDefaultAdd ? null : undefined);
    const onChangeAddress = props.onChangeAddress || function(){};
    const close = props.close || function(){};
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    return (
        <div className={styles.modalContainer}>
            {
                targetAddress === undefined ?
                    (
                        <div className={utilStyles.flexCol} style={{height: '100%'}}>
                            <div className={classNames(utilStyles.topSticky, utilStyles.whiteBackground, utilStyles.fullWidth)}>
                                <TitleBar title={t('AddressList.selectAddress')} isClose close={close} />
                            </div>
                            <div className={styles.addButton}>
                                <FullButton fontSize={isMobile ? 15 : 16} white disabled={isLoading} height={44} onClick={()=> !isLoading && setTargetAddress(null)}>
                                    <Trans
                                        i18nKey="components-modal-DeliveryAddressModal:AddressList.addNewAddress"
                                        components={[<span className={styles.addText} />, <PlusIcon className={utilStyles.verticalMiddle} />]}
                                    />
                                </FullButton>
                            </div>
                            <div {...{'body-scroll-lock-ignore': "true"}}>
                                {
                                    !!user &&
                                    user.addresses.map(address => {
                                        return (
                                            <div key={address.id} className={styles.addressCardWrapper}>
                                                <AddressCard
                                                    close={close}
                                                    onChangeAddress={onChangeAddress}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    defaultAddressId={user.default_address_id}
                                                    address={address}
                                                    onEdit={()=>setTargetAddress(address)} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                    :
                    <AddressAdd isClose={isDefaultAdd} isBack={!isDefaultAdd} close={() => {
                        if (!!user && user.addresses.length === 0) {
                            close();
                        } else {
                            setTargetAddress(undefined);
                        }
                    }} targetAddress={targetAddress} />
            }
        </div>
    );
}
