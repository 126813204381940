import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import checkoutStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import CheckoutAddressCard from "components/cards/CheckoutAddressCard";
import classNames from "classnames";
import TextInputDropdown from "components/input/TextInputDropdown";
import TextInput from "components/input/TextInput";
import {
    deliveryMemoChoices,
    KEY_ADDRESS_ID,
    KEY_DELIVERY_MEMO,
    KEY_DETAIL_ADDRESS, KEY_ORDERER_NAME, KEY_ORDERER_PHONE_NUMBER,
    KEY_POSTAL_CODE,
    KEY_RECEIVER_NAME,
    KEY_RECEIVER_PHONE_NUMBER,
    KEY_ROUGH_ADDRESS,
    VALUE_SELF_ENTER
} from "pages/store/orders/[ordNum]/checkout";
import {useContext, useRef, useState} from "react";
import UserContext from "context/AuthContext";
import CheckedCheckboxIcon from "images/icons/checkbox_checked.svg";
import utilStyles from "styles/utils.module.scss";
import UncheckedCheckboxIcon from "images/icons/checkbox_unchecked.svg";
import ArrowRightIcon from "images/icons/arrow_right.svg";
import useTranslation from "next-translate/useTranslation";


const CheckoutAddressInfoSection = (props) => {
    const {isLoading, formik} = props;

    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const [isAddressListModalOpen, setIsAddressListModalOpen] = useState(false);
    const onChangeClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutAddressChangeButtonClick');
        if (isLoading) return;
        setIsAddressListModalOpen(val => !val);
    };

    const nameInputRef = useRef(null);

    return (
        <div className={styles.section}>
            <div className={classNames(styles.title, utilStyles.cursorPointer, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentSpaceBetween)}>
                <span>배송정보</span>
                {
                    !values[KEY_ADDRESS_ID] ?
                        <div onClick={() => {
                            if (isLoading) return;

                            if ((values[KEY_ORDERER_NAME] || values[KEY_ORDERER_PHONE_NUMBER]) && values[KEY_ORDERER_NAME] === values[KEY_RECEIVER_NAME] && values[KEY_ORDERER_PHONE_NUMBER] === values[KEY_RECEIVER_PHONE_NUMBER]) {
                                setValues(oldValues => {
                                    const newValues = JSON.parse(JSON.stringify(oldValues));
                                    newValues[KEY_RECEIVER_NAME] = '';
                                    newValues[KEY_RECEIVER_PHONE_NUMBER] = '';
                                    return newValues;
                                });
                                if (nameInputRef.current) {
                                    nameInputRef.current.focus();
                                }
                            } else if (values[KEY_ORDERER_NAME] || values[KEY_ORDERER_PHONE_NUMBER]) {
                                setValues(oldValues => {
                                    const newValues = JSON.parse(JSON.stringify(oldValues));
                                    newValues[KEY_RECEIVER_PHONE_NUMBER] = values[KEY_ORDERER_PHONE_NUMBER];
                                    newValues[KEY_RECEIVER_NAME] = values[KEY_ORDERER_NAME];
                                    return newValues;
                                })
                            }
                        }}>
                            {
                                (!!values[KEY_ORDERER_NAME] || !!values[KEY_ORDERER_PHONE_NUMBER]) && values[KEY_ORDERER_NAME] === values[KEY_RECEIVER_NAME] && values[KEY_ORDERER_PHONE_NUMBER] === values[KEY_RECEIVER_PHONE_NUMBER] ?
                                    <CheckedCheckboxIcon viewBox="0 0 24 24" width="20" height="20"
                                                         className={utilStyles.verticalMiddle}/>
                                    :
                                    <UncheckedCheckboxIcon viewBox="0 0 24 24" width="20" height="20"
                                                           className={utilStyles.verticalMiddle}/>
                            }
                            <span className={styles.sameAsOrderer}>주문자 정보와 동일</span>
                        </div>
                        :
                        (
                            (!!user && values[KEY_ADDRESS_ID]) &&
                            <div className={utilStyles.cursorPointer} onClick={onChangeClick}>
                                <span className={checkoutStyles.rightAction}>변경</span>
                                <ArrowRightIcon viewBox="0 0 16 16"
                                                className={checkoutStyles.rightActionArrow}/>
                            </div>
                        )
                }
            </div>
                <CheckoutAddressCard
                    isAddressListModalOpen={isAddressListModalOpen}
                    setIsAddressListModalOpen={setIsAddressListModalOpen}
                    errors={errors}
                    touched={touched}
                    nameInputRef={nameInputRef}
                    isLoading={isLoading}
                    user={userContext.user}
                    onChangeAddress={(address) => {
                        setValues(oldValues => {
                            const newValues = JSON.parse(JSON.stringify(oldValues));
                            newValues[KEY_RECEIVER_NAME] = address[KEY_RECEIVER_NAME];
                            newValues[KEY_POSTAL_CODE] = address[KEY_POSTAL_CODE];
                            newValues[KEY_ROUGH_ADDRESS] = address[KEY_ROUGH_ADDRESS];
                            newValues[KEY_RECEIVER_PHONE_NUMBER] = address.phone_number;
                            newValues[KEY_DELIVERY_MEMO] = address.memo;
                            newValues[KEY_DETAIL_ADDRESS] = address[KEY_DETAIL_ADDRESS];
                            newValues[KEY_ADDRESS_ID] = address.id;
                            return newValues;
                        });
                    }}
                    setValues={setValues}
                    values={JSON.parse(JSON.stringify(values))}
                    setFieldValue={setFieldValue}
             />
        </div>
    )
};

export default CheckoutAddressInfoSection;
