import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import {toKorDate} from "common/timeConverter";


const CheckoutReservationSelectedDate = (props) => {
    const {reservationSelectedDate} = props;

    return (
        <div className={styles.section}>
            <div
                className={classNames(styles.title, utilStyles.cursorPointer, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentSpaceBetween)}>
                <span>방문예정일</span>
                <div className={styles.textContent}>{toKorDate(reservationSelectedDate)}</div>
            </div>
        </div>
    )
};

export default CheckoutReservationSelectedDate;
