import {useLayoutEffect} from "react";

import Cookies from "js-cookie";
import {getLocalizationInfoInCSR} from "common/i18n";
import {saveCountryCode} from "common/utils";
import {useRouter} from "next/router";
import {COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES} from "common/country_list";
import {COUNTRY_CODE} from "common/const";


const usePersistLocalizationCookie = () => {
    const {locale} = useRouter();

    const persistLocaleCookie = () => {
        const {countryCode} = getLocalizationInfoInCSR();
        const siteUrl = `.${window.location.host.split('.').slice(-2).join('.')}`;

        if (locale !== Cookies.get("NEXT_LOCALE")) {
            const date = new Date();
            const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
            date.setTime(date.getTime() + expireMs);
            if (countryCode === 'global') {
                Cookies.set("NEXT_LOCALE", 'en', {
                    expires: 365, domain: siteUrl,
                })
            } else {
                Cookies.set("NEXT_LOCALE", locale, {
                    expires: 365, domain: siteUrl,
                });
            }
        }

        const nextCountry = Cookies.get("NEXT_COUNTRY");
        const targetCountryCode = (
            nextCountry && COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES[COUNTRY_CODE].includes(nextCountry)
        ) ? nextCountry: COUNTRY_CODE;


        Cookies.set("NEXT_COUNTRY", targetCountryCode, {
            expires: 365, domain: siteUrl,
        })
        saveCountryCode(targetCountryCode);
    };

    useLayoutEffect(persistLocaleCookie, [locale]);
};

export default usePersistLocalizationCookie;
