import {countryCodeToChoice} from "common/country_list";
import {useMemo} from "react";
import styles from "components/country/CountryImage.module.scss";
import Image from "next/image";
import classNames from "classnames";

const possibleSize = [
    16, 24, 32, 48, 64,
]

export const CountryFlagImage = (props) => {
    const {size, circle, countryCode, showDescription} = props;
    const targetSize = size || 24;

    if (!countryCode) {
        return null;
    }

    const imageSize = useMemo(() => {
        let apiSize = targetSize;

        for (let i = 0; i < possibleSize.length; i++) {
            if (targetSize < possibleSize[i]) {
                apiSize = possibleSize[i];
                break;
            }
        }

        return apiSize;
    }, [targetSize]);

    const choice = countryCodeToChoice[countryCode];

    return (
        <div className={styles.countryImageRootContainer}>
            <div className={classNames(styles.flagImageCircle, circle ? styles.flagImageCircle : null)}
                 style={{borderRadius: circle ? targetSize: null}}>
            {
                (choice?.image) ? (
                    <choice.image width={imageSize} height={imageSize}/>
                ) :
                    <Image src={`https://flagsapi.com/${countryCode}/flat/${targetSize}.png`}
                           alt={countryCode} key={`flag-${countryCode}`} width={targetSize} height={targetSize}/>
            }
            </div>
            {showDescription && (
                <div className={styles.descriptionContainer}>
                    {`${choice?.description || countryCode} (${choice?.currency})`}
                </div>)
            }
        </div>
    )
}