import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import LinesEllipsis from "react-lines-ellipsis";
import ArrowDownIcon from "images/icons/arrow_down_757575.svg";
import NewCheckoutOptionCard from "components/cards/NewCheckoutOptionCard";
import {useEffect, useState} from "react";
import {Axios} from "api";
import {captureException} from "@sentry/nextjs";
import useTranslation from "next-translate/useTranslation";


const CheckoutOrderProductSection = (props) => {
    const {order, selectedCoupons, isReservation} = props;

    const [isOptionFold, setIsOptionFold] = useState(false);
    const {t} = useTranslation("component-checkout-order-product-section");
    const onOptionFoldClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageOptionFoldClick', {isOptionFold: isOptionFold});
        setIsOptionFold(val => !val);
    };

    const firstOptionBrand = order && order.option_count_list.length > 0
        ? order.option_count_list[0].product.brand_name
        : "";

    const [referralIdToNickname, setReferralIdToNickname] = useState({});
    useEffect(async () => {
        if (order) {
            try {
                const res = await Axios.post('v1/palette/get-creator-nickname/', {
                    referral_ids: order.option_count_list.filter(x => x.referral_id).map(x => x.referral_id)
                });
                if (res.status < 400) {
                    setReferralIdToNickname(res.data.referral_id_to_nickname);
                    return;
                }
            } catch (e) {
                captureException(e);
            } finally {
            }
            setReferralIdToNickname({});
        }
    }, [order]);

    const isGift = order.order.is_gift;
    const title = props.title || (isGift ? t('gift') : t('orderProduct'));

    const brandNameToOptionCount = {};
    for (const optionCount of order.option_count_list) {
        const brandName = optionCount.product.brand_name;
        if (!(brandName in brandNameToOptionCount)) {
            brandNameToOptionCount[brandName] = [];
        }
        brandNameToOptionCount[brandName].push(optionCount);
    }

    return (
        <div className={styles.section}
             style={{paddingLeft: 0, paddingRight: 0, marginBottom: isOptionFold ? 24 : 0}}
        >
            <div
                className={classNames(styles.title, utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter, utilStyles.cursorPointer)}
                onClick={onOptionFoldClick}
                style={{paddingLeft: 16, paddingRight: 16}}
            >
              <span style={{minWidth: 60, marginRight: 40}}>
                  {title}
              </span>
                <div className={styles.brief}>
                    {
                        isOptionFold &&
                        <>
                            <span className={classNames(styles.verticalMiddle, utilStyles.inlineBlock)}
                                  style={{flex: 1, whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>
                                <LinesEllipsis
                                    text={order.option_count_list ? `[${firstOptionBrand}] ${order.option_count_list[0].product.name}` : ''}
                                    maxLine={1}
                                    ellipsis="..."
                                    trimRight
                                    basedOn='letters'
                                />
                            </span>
                            {order.option_count_list ? <>&nbsp;</> : <></>}
                            <span className={styles.verticalMiddle}
                                  style={{wordBreak: 'keep-all'}}>{order.option_count_list ? t('order.include') : ''}</span>
                            {order.option_count_list ? <>&nbsp;</> : <></>}
                            <span className={classNames(styles.verticalMiddle, styles.optionCount)}
                                  style={{wordBreak: 'keep-all'}}>
                              {order.option_count_list ? t('order.getCase', {count: order.option_count_list.length}) : ''}
                          </span>
                        </>
                    }
                    <ArrowDownIcon className={styles.verticalMiddle} viewBox="0 0 20 20" width="16" height="16"
                                   style={{marginLeft: 8, transform: !isOptionFold ? 'rotate(180deg)' : 'rotate(0deg)'}}
                    />
                </div>
            </div>
            {
                order.option_count_list && !isOptionFold && Object.entries(brandNameToOptionCount).map((entry, i) => {
                    const [brandName, optionCountList] = entry;
                    return (
                        <div>
                            {
                                optionCountList.map((e, idx) => {
                                    return (
                                        <>
                                            <div className={styles.optionCard} key={e.id}>
                                                <NewCheckoutOptionCard
                                                    orderInfo={order.order}
                                                    optionCountInfo={e} referralIdToNickname={referralIdToNickname}
                                                    selectedCoupons={selectedCoupons}
                                                    isReservation={isReservation}
                                                    noTitleBar={idx !== 0}
                                                    noBrandName={idx !== 0}
                                                />
                                            </div>
                                        </>
                                    )
                                })
                            }
                            {/*{i < Object.entries(brandNameToOptionCount).length - 1 && <div className={utilStyles.mobileBorder}/>}*/}
                        </div>
                    );
                })
            }
        </div>
    )
};

export default CheckoutOrderProductSection;
