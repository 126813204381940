import Cookies from "js-cookie";
import {Deeplink, getCurrentHref} from "common/utils";
import {getHmkpid} from "common/hmkcid";
import {loginRoute} from "common/const";
import {GLOBAL_COUNTRY_CODE, JAPAN_COUNTRY_CODE, KOREA_COUNTRY_CODE, US_COUNTRY_CODE} from "./i18n";


const filterCookieString = (cookieString, prefixList) => {
    const cookies = cookieString.split(';');
    let filteredString = '';
    for (let i = 1; i <= cookies.length; i++) {
        const cookie = cookies[i - 1].trim();
        const cookieKey = cookie.split('=')[0];
        if (prefixList.some(prefix => cookieKey.startsWith(prefix))) {
            filteredString += cookie + ';';
        }
    }
    return filteredString;
};

export const getAppRedirectUrl = () => {
    const targetUrl = new URL(`hemekolab://webview${getCurrentHref()}`);
    const params = new URLSearchParams(targetUrl.search);
    const filteredCookieString = filterCookieString(
        String(document.cookie), ['_ga', '_gcl']
    );
    const fbc = Cookies.get('_fbc');
    const localHmkCidRecord = window.localStorage.getItem('hmkcid');
    const hmkpid = getHmkpid();
    if (hmkpid) {
        params.set('hmkpid', hmkpid);
    }
    if (filteredCookieString) {
        params.set('gainfo', filteredCookieString);
    }
    if (fbc && fbc !== 'undefined') {
        params.set('facebookclickid', fbc);
    }
    if (localHmkCidRecord) {
        try {
            const localHmkCidObject = JSON.parse(localHmkCidRecord);
            params.set('hmkcid', localHmkCidObject.hmkCid);
        } catch {
        }
    }
    targetUrl.search = params.toString();
    const url = targetUrl.toString();
    return url;
}

export const redirectToApp = () => {
    const finalUrl = getAppRedirectUrl();
    const linker = Deeplink({
        iTunesLink: 'https://itunes.apple.com/kr/app/id6448773004',
        playStoreLink: `https://play.google.com/store/apps/details?id=com.hemekolab.app&referrer=ref=${encodeURIComponent(finalUrl)}`,
        androidPackage: 'com.hemekolab.app',
        useFallback: true,
        fallbackUrl: `https://play.google.com/store/apps/details?id=com.hemekolab.app&referrer=ref=${encodeURIComponent(finalUrl)}`,
    });
    linker.redirectToApp(finalUrl);
};

export const redirectCountryToMain = (countryCode) => {
    let domain;
    if (countryCode === GLOBAL_COUNTRY_CODE) {
        domain = process.env.GLOBAL_DOMAIN;
    } else if (countryCode === US_COUNTRY_CODE) {
        domain = process.env.US_DOMAIN;
    } else if (countryCode === KOREA_COUNTRY_CODE) {
        domain = process.env.KR_DOMAIN;
    } else if (countryCode === JAPAN_COUNTRY_CODE) {
        domain = process.env.JP_DOMAIN;
    } else {
        domain = process.env.US_DOMAIN
    }
    if (domain) {
        const previousPathname = window.location.search;
        const newPathname = previousPathname ? `${previousPathname}&saveCountry=true` : '?saveCountry=true';
        window.location.href = `https://${domain}${window.location.pathname}${newPathname}`;
    }
};

export const redirectToLogin = (router, isPush, anchor, redirectUrl, preventGoBack) => {
    let path = redirectUrl ? redirectUrl : router.asPath;
    if (anchor) {
        path += `#${anchor}`;
    }
    const redirect = encodeURIComponent(path);
    const func = isPush ? router.push : router.replace;
    func({
        pathname: `${loginRoute}`,
        query: {
            redirect: `${redirect}`,
            preventGoBack: !!preventGoBack,
        },
    });
};
