import * as yup from "yup";

const formikRegexSchema = (
    {
        field_name, arg, message = null, required = true,
        showing_name = null,
    }, prevFormik = null,
) => {
    let yupSchema = prevFormik || yup.string();
    const showingFieldName = (
        showing_name || field_name.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    );

    if (required) {
        yupSchema = yupSchema.required(`${showingFieldName} is required`);
    }

    yupSchema = yupSchema.matches(arg, message || `${showingFieldName} is invalid`);

    return yupSchema;
}

export const formikStringFactory = (props, prevFormik = null) => {
    const {type} = props;

    if (type === 'regex') {
        return formikRegexSchema(props, prevFormik);
    }

    return yup.string();
}