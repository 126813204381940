import NotFoundIcon from "images/icons/not_found.svg";
import ServerErrorIcon from "images/icons/server_error.svg";
import FullButton from "components/buttons/FullButton";
import styles from "components/errorPage.module.scss";
import utilStyles from 'styles/utils.module.scss';
import {useRouter} from "next/router";
import {storeRoute} from "common/const";


export default function ErrorPage (props) {
    const {serverError, title, content} = props;
    const router = useRouter();

    return (
        <div>
            <div className={utilStyles.pageContainer} style={{width: '100%', textAlign: 'center'}}>
                <div style={{height: 100}} />
                {
                    serverError ?
                        <ServerErrorIcon />
                        :
                        <NotFoundIcon />
                }
                <div style={{height: 40}} />
                <span className={styles.title}>{title}</span>
                <div style={{height: 20}} />
                <span className={styles.content}>{content.split('\n').map((e, i) => {
                    return (
                        <span key={i}>
                            {e}<br/>
                        </span>
                    )
                })}</span>
                <div style={{height: 60}} />
                <div style={{width: 168, margin: 'auto'}}>
                    <FullButton onClick={() => router.replace({pathname: storeRoute})} title="메인으로" height={60} fontSize={20} />
                </div>
            </div>
        </div>
    )
};
