import {useRouter} from "next/router";
import {useContext, useEffect, useMemo, useState} from "react";
import UserContext from "context/AuthContext";
import {useFormik} from "formik";
import * as yup from "yup";
import {getSuccessRoute, loginRoute, PORT_ONE_KEY, SERVICE_ENV} from "common/const";
import {Axios} from "api";
import {formattedPrice, getLocalStorageCountryCode, getLoggingDataFromOptionCount, numberWithComma} from "common/utils";
import {toast} from "react-toastify";
import {captureException, captureMessage} from "@sentry/nextjs";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import TitleBar from "components/layout/TitleBar";
import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import Link from "next/link";
import FullButton from "components/buttons/FullButton";
import CheckoutUserInfoSection from "components/sections/checkout/CheckoutUserInfoSection";
import CheckoutOrderProductSection from "components/sections/checkout/CheckoutOrderProductSection";
import CheckoutGiftReceiverSection from "components/sections/checkout/CheckoutGiftReceiverSection";
import CheckoutCouponInfoSection from "components/sections/checkout/CheckoutCouponInfoSection";
import CheckoutPriceInfoSection from "components/sections/checkout/CheckoutPriceInfoSection";
import CheckoutAgreementSection from "components/sections/checkout/agreement/CheckoutAgreementSection";
import {
    KEY_ADDRESS_CITY,
    KEY_ADDRESS_COUNTRY,
    KEY_ADDRESS_ID,
    KEY_ADDRESS_LINE_1,
    KEY_ADDRESS_LINE_2,
    KEY_ADDRESS_STATE,
    KEY_ADDRESS_ZIP_CODE,
    KEY_AGREEMENT_CHECKED,
    KEY_BILLING_INFO_CITY,
    KEY_BILLING_INFO_COUNTRY,
    KEY_BILLING_INFO_EMAIL, KEY_BILLING_INFO_FIRST_NAME, KEY_BILLING_INFO_LAST_NAME,
    KEY_BILLING_INFO_LINE_1,
    KEY_BILLING_INFO_LINE_2,
    KEY_BILLING_INFO_PHONE_COUNTRY_CODE,
    KEY_BILLING_INFO_PHONE_NUMBER,
    KEY_BILLING_INFO_STATE,
    KEY_BILLING_INFO_ZIP_CODE,
    KEY_DELIVERY_MEMO,
    KEY_DETAIL_ADDRESS,
    KEY_GIFT_RECEIVER_NAME,
    KEY_IS_ESCROW,
    KEY_ORDERER_EMAIL,
    KEY_ORDERER_NAME,
    KEY_ORDERER_PHONE_NUMBER,
    KEY_OVERSEAS_EMAIL,
    KEY_OVERSEAS_FIRST_NAME,
    KEY_OVERSEAS_LAST_NAME,
    KEY_OVERSEAS_ORDER_INFO,
    KEY_OVERSEAS_PHONE_COUNTRY_CODE,
    KEY_OVERSEAS_PHONE_NUMBER,
    KEY_PAY_METHOD,
    KEY_PG,
    KEY_POSTAL_CODE,
    KEY_RECEIVER_NAME,
    KEY_RECEIVER_PHONE_NUMBER,
    KEY_ROUGH_ADDRESS,
    KEY_SELECTED_COUPONS,
    KEY_SUGGEST_BEST_COUPON,
    KEY_USED_POINTS,
    KEY_VERIFIED_PHONE_NUMBER
} from "pages/store/orders/[ordNum]/checkout"
import Loading from "components/Loading";
import useTranslation from "next-translate/useTranslation";
import CheckoutAddressInfoSectionGlobal from "components/sections/checkout/CheckoutAddressInfoSectionGlobal";
import {getPhoneCodeByCode, getStateCodeFromFullName} from "common/country_list";
import PortOneScript from "components/PortOneScript";
import {priceToCurrencyPrice} from "common/price_utils";
import DeliveryContext from "context/DeliveryContext";
import {formikStringFactory} from "common/delivery/formikDeliveryValidator";

const USCheckoutInfo = (props) => {
    const {order, setOrder, isVerificationRequired} = props;
    const ordNum = order ? order.order.order_number : '';
    const {t} = useTranslation('sections-checkout-CheckoutInfo');
    const countryCode = order ? (order?.order?.delivery_country_code || order.order.country_code) : getLocalStorageCountryCode() ?? 'KR';
    const isGift = order ? order.order.is_gift : false;
    const isReservation = order ? order.order.is_reservation : false;
    const [isPaypalLoaded, setIsPaypalLoaded] = useState(false);
    const [isOrderValidating, setIsOrderValidating] = useState(false);
    const {deliveryStrategy, fetchDeliveryStrategy} = useContext(DeliveryContext);

    const router = useRouter();
    const locale = router.locale;
    const query = router.query;

    const [isLoading, setIsLoading] = useState(false);

    const userContext = useContext(UserContext);
    const user = userContext.user;

    const [verificationNumber, setVerificationNumber] = useState(undefined);

    if (!order) {
        return <div></div>
    }

    const currency = useMemo(() => {
        return order?.currency || 'USD';
    }, [order]);

    const fieldNameToValidator = useMemo(() => {
        let result = {};

        deliveryStrategy?.validators?.forEach(e => {
            result[e.field_name] = formikStringFactory(e, result[e.field_name]);
        });

        return result;
    }, [deliveryStrategy]);

    useEffect(() => {
        if (query && query.notice) {
            if (query.notice === 'unavailable_international_brand') {
                toast.info('Please select CreditCard(3DS) for this card.');
            }
            const nextQuery = {...router.query};
            delete nextQuery.notice;
            router.replace({
                pathname: router.pathname,
                query: nextQuery,
            }, undefined, {shallow: true});
        }
    }, [query]);

    const getInitialValues = () => {

        if (!(order && order.order && order.order[KEY_ADDRESS_ZIP_CODE])) {
            let defaultAddress = {};
            if (user) {
                const addresses = user?.addresses.filter(e => e.country_code === countryCode);
                if (!isGift && addresses?.length > 0) {
                    const userDefaultAddress = addresses.filter(e => e.id === user.default_address_id)[0];
                    if (userDefaultAddress) {

                        defaultAddress = userDefaultAddress;
                    } else {
                        defaultAddress = user.addresses[0];
                    }
                } else {
                    defaultAddress = {};
                }
            }
            return {
                [KEY_ADDRESS_ID]: defaultAddress[KEY_ADDRESS_ID] || '',
                [KEY_ADDRESS_ZIP_CODE]: defaultAddress[KEY_POSTAL_CODE] || '',
                [KEY_ADDRESS_LINE_1]: defaultAddress[KEY_ROUGH_ADDRESS] || '',
                [KEY_ADDRESS_LINE_2]: defaultAddress[KEY_DETAIL_ADDRESS] || '',
                [KEY_ADDRESS_CITY]: defaultAddress['city'] || '',
                [KEY_ADDRESS_STATE]: defaultAddress['state'] || '',
                [KEY_OVERSEAS_PHONE_NUMBER]: defaultAddress.phone_number || '',
                [KEY_OVERSEAS_PHONE_COUNTRY_CODE]: defaultAddress.phone_country_code || getPhoneCodeByCode(countryCode) || '',
                [KEY_OVERSEAS_LAST_NAME]: defaultAddress[KEY_RECEIVER_NAME]?.split(', ')[0] || '',
                [KEY_OVERSEAS_FIRST_NAME]: defaultAddress[KEY_RECEIVER_NAME]?.split(', ')[1] || '',
                [KEY_DELIVERY_MEMO]: defaultAddress.memo || '',
                [KEY_PAY_METHOD]: order ? order.available_pay_methods[0].pay_method : '',
                [KEY_PG]: order ? order.available_pay_methods[0].pg : '',
                [KEY_SELECTED_COUPONS]: order ? order[KEY_SELECTED_COUPONS] : [],
                [KEY_USED_POINTS]: 0,
                [KEY_ADDRESS_ID]: isGift ? null : (defaultAddress.id || null),
                [KEY_ORDERER_NAME]: '',
                [KEY_ORDERER_PHONE_NUMBER]: user ? user.sns_phone_number : '',
                [KEY_ORDERER_EMAIL]: user ? user.email : '',
                [KEY_OVERSEAS_EMAIL]: defaultAddress[KEY_OVERSEAS_EMAIL] || '',
                [KEY_AGREEMENT_CHECKED]: true,
                [KEY_IS_ESCROW]: false,
                [KEY_GIFT_RECEIVER_NAME]: '',
                [KEY_VERIFIED_PHONE_NUMBER]: '',
                [KEY_ADDRESS_COUNTRY]: countryCode
            }

        }
        return {
            [KEY_ADDRESS_ID]: order ? order.order[KEY_ADDRESS_ID] : '',
            [KEY_OVERSEAS_FIRST_NAME]: '',
            [KEY_OVERSEAS_LAST_NAME]: '',
            [KEY_ORDERER_EMAIL]: user ? user.email : '',
            [KEY_OVERSEAS_EMAIL]: '',
            [KEY_OVERSEAS_PHONE_COUNTRY_CODE]: getPhoneCodeByCode(countryCode),
            [KEY_OVERSEAS_PHONE_NUMBER]: '',
            [KEY_ADDRESS_COUNTRY]: countryCode,
            [KEY_ADDRESS_ZIP_CODE]: '',
            [KEY_ADDRESS_STATE]: '',
            [KEY_ADDRESS_CITY]: '',
            [KEY_ADDRESS_LINE_1]: '',
            [KEY_ADDRESS_LINE_2]: '',
            [KEY_DELIVERY_MEMO]: '',
            [KEY_PAY_METHOD]: order ? order.available_pay_methods[0].pay_method : '',
            [KEY_PG]: order ? order.available_pay_methods[0].pg : '',
            [KEY_SELECTED_COUPONS]: order ? order[KEY_SELECTED_COUPONS] : [],
            [KEY_USED_POINTS]: 0,
            [KEY_AGREEMENT_CHECKED]: true,
            [KEY_IS_ESCROW]: false,
            [KEY_GIFT_RECEIVER_NAME]: '',
            [KEY_VERIFIED_PHONE_NUMBER]: '',
        }

    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: getInitialValues(),
        validationSchema: yup.object({
            [KEY_ADDRESS_ID]: fieldNameToValidator[KEY_ADDRESS_ID] || yup.number().nullable().min(1),
            [KEY_DELIVERY_MEMO]: fieldNameToValidator[KEY_DELIVERY_MEMO] || yup.string().max(50),
            [KEY_PAY_METHOD]: yup.string().nullable(true).oneOf(order.available_pay_methods.map(e => e.pay_method).concat([null])),
            [KEY_PG]: yup.string().nullable(true).oneOf(order.available_pay_methods.map(e => e.pg).concat([null])),
            [KEY_ADDRESS_ZIP_CODE]: fieldNameToValidator[KEY_ADDRESS_ZIP_CODE] || (!(isGift || isReservation) ? yup.string().required('Zip Code is required.').matches(/^\d*$/).min(2).max(20) : yup.string()),
            [KEY_ADDRESS_STATE]: fieldNameToValidator[KEY_ADDRESS_STATE] || (!(isGift || isReservation) ? yup.string().required('State/Province is required.') : yup.string()),
            [KEY_ADDRESS_CITY]: fieldNameToValidator[KEY_ADDRESS_CITY] || (!(isGift || isReservation) ? yup.string().max(40).required('City is required.') : yup.string()),
            [KEY_ADDRESS_LINE_1]: fieldNameToValidator[KEY_ADDRESS_LINE_1] || (!(isGift || isReservation) ? yup.string().required('Address is required.') : yup.string()),
            [KEY_ADDRESS_LINE_2]: fieldNameToValidator[KEY_ADDRESS_LINE_2] || (!(isGift || isReservation) ? yup.string() : yup.string()),
            [KEY_OVERSEAS_PHONE_COUNTRY_CODE]: fieldNameToValidator[KEY_OVERSEAS_PHONE_COUNTRY_CODE] || yup.string().required('Country Code is required.').matches(/^\+\d{3}$/, 'Format must be +000'),
            [KEY_OVERSEAS_PHONE_NUMBER]: fieldNameToValidator[KEY_OVERSEAS_PHONE_NUMBER] || (!(isGift || isReservation) ? yup.string().required('Phone Number is required.').max(20, 'Phone Number must be at most 13 characters') : yup.string()),
            [KEY_OVERSEAS_FIRST_NAME]: fieldNameToValidator[KEY_OVERSEAS_FIRST_NAME] || (!(isGift || isReservation) ? yup.string().required('Name is required.').max(64) : yup.string()),
            [KEY_OVERSEAS_LAST_NAME]: fieldNameToValidator[KEY_OVERSEAS_LAST_NAME] || (!(isGift || isReservation) ? yup.string().required('Name is required.').max(64) : yup.string()),
            [KEY_OVERSEAS_EMAIL]: yup.string().email('The email format is invalid.').required('Email is required. Please enter Email.'),
            [KEY_AGREEMENT_CHECKED]: yup.bool().required().oneOf([true]),
            [KEY_GIFT_RECEIVER_NAME]: (isGift ? yup.string().required('Name is required. Please enter Name.').max(10, 'Name is Max length under 10') : yup.string()),
            [KEY_ADDRESS_COUNTRY]: yup.string()
        }),
        onSubmit: values => {
            if (currency === 'USD') {
                return;
            }
        }
    })

    const {values, setFieldValue, setValues, handleSubmit, errors, validateForm, touched, resetForm} = formik;

    useEffect(() => {
        if (values[KEY_ADDRESS_ID] && !(user?.addresses?.some(e => e.id === values[KEY_ADDRESS_ID]))) {
            resetForm();
        }
    }, [user?.addresses]);

    const selectedCoupons = useMemo(() => {
        return values[KEY_SELECTED_COUPONS] || [];
    }, [values]);

    const fetchOrder = async () => {
        try {
            if (!values[KEY_ADDRESS_COUNTRY]) {
                return;
            }

            const res = await Axios.post(`v1/store/orders/${order.order.order_number}/cost`, {
                [KEY_SELECTED_COUPONS]: selectedCoupons,
                [KEY_SUGGEST_BEST_COUPON]: false,
                [KEY_ADDRESS_COUNTRY]: values[KEY_ADDRESS_COUNTRY],
                [KEY_ADDRESS_ZIP_CODE]: values[KEY_ADDRESS_ZIP_CODE] || null,
            });

            if (res.status < 400) {
                setOrder(res.data);
            } else {
                captureException(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        }
    }

    useEffect(() => {
        if (userContext.user && (userContext.user.name || userContext.user.phone_number)) {
            setValues(oldValues => {
                const newValues = JSON.parse(JSON.stringify(oldValues));
                if (userContext.user.name) newValues[KEY_ORDERER_NAME] = userContext.user.name;
                if (userContext.user.phone_number) {
                    newValues[KEY_ORDERER_PHONE_NUMBER] = userContext.user.phone_number;
                    newValues[KEY_VERIFIED_PHONE_NUMBER] = userContext.user.phone_number;
                }
                return newValues;
            })
        }
    }, [userContext.user]);

    useEffect(async () => {
        if (!values[KEY_ADDRESS_COUNTRY]) {
            return;
        }

        await fetchDeliveryStrategy(values[KEY_ADDRESS_COUNTRY]);
        await fetchOrder();
        setValues({
            ...getInitialValues(),
            [KEY_ADDRESS_COUNTRY]: values[KEY_ADDRESS_COUNTRY],
            [KEY_OVERSEAS_PHONE_COUNTRY_CODE]: getPhoneCodeByCode(values[KEY_ADDRESS_COUNTRY]),
        })
        // resetForm();
    }, [values[KEY_ADDRESS_COUNTRY]])

    useEffect(async () => {
        if (!values[KEY_ADDRESS_COUNTRY] || errors[KEY_ADDRESS_ZIP_CODE] || !values[KEY_ADDRESS_ZIP_CODE]) {
            return;
        }

        await fetchOrder();
    }, [errors[KEY_ADDRESS_ZIP_CODE], values[KEY_ADDRESS_ZIP_CODE]])

    const validateOrder = async () => {
        setIsOrderValidating(true);
        let data = {}
        let global_order_info = {}
        global_order_info[KEY_ADDRESS_LINE_1] = values[KEY_ADDRESS_LINE_1];
        global_order_info[KEY_ADDRESS_LINE_2] = values[KEY_ADDRESS_LINE_2];
        global_order_info[KEY_ADDRESS_ZIP_CODE] = values[KEY_ADDRESS_ZIP_CODE];
        global_order_info[KEY_ADDRESS_CITY] = values[KEY_ADDRESS_CITY];
        global_order_info[KEY_ADDRESS_STATE] = values[KEY_ADDRESS_STATE];
        global_order_info[KEY_ADDRESS_ZIP_CODE] = values[KEY_ADDRESS_ZIP_CODE];
        global_order_info[KEY_OVERSEAS_FIRST_NAME] = values[KEY_OVERSEAS_FIRST_NAME];
        global_order_info[KEY_OVERSEAS_LAST_NAME] = values[KEY_OVERSEAS_LAST_NAME];
        global_order_info[KEY_OVERSEAS_EMAIL] = values[KEY_OVERSEAS_EMAIL];
        global_order_info[KEY_OVERSEAS_PHONE_COUNTRY_CODE] = values[KEY_OVERSEAS_PHONE_COUNTRY_CODE];
        global_order_info[KEY_OVERSEAS_PHONE_NUMBER] = values[KEY_OVERSEAS_PHONE_NUMBER];
        global_order_info[KEY_ADDRESS_COUNTRY] = values[KEY_ADDRESS_COUNTRY];
        data = JSON.parse(JSON.stringify(values))
        data[KEY_OVERSEAS_ORDER_INFO] = global_order_info
        data[KEY_SUGGEST_BEST_COUPON] = false;
        try {
            const res = await Axios.patch(`v1/store/orders/${ordNum}/info`, data);
            if (res.status < 400) {
                const newOrder = res.data;
                const optionCountList = newOrder.option_count_list;
                const totalPrice = newOrder.total_price;
                const totalCount = optionCountList.reduce((acc, cur) => acc + cur.count, 0);
                const totalUniqueOptionCount = optionCountList.length;
                const isLoginPurchase = Boolean(user);
                typeof mixpanel !== 'undefined' && mixpanel.track('ConfirmCheckout',
                    {
                        order_number: ordNum,
                        is_login_purchase: isLoginPurchase,
                        totalPrice,
                        totalCount,
                        totalUniqueOptionCount,
                    }
                );
                for (const optionCount of optionCountList) {
                    const loggingData = getLoggingDataFromOptionCount(optionCount, ordNum, isLoginPurchase);
                    typeof mixpanel !== 'undefined' && mixpanel.track('ConfirmCheckoutOrderOption', loggingData);
                }
                if (0 < (values[KEY_USED_POINTS] || 0) && (values[KEY_USED_POINTS] || 0) < order.min_usable_points) {
                    toast.info(`포인트는 ${order.min_usable_points}p 이상만 사용할 수 있습니다.`);
                    return;
                }
                if (order.total_price_without_point - (values[KEY_USED_POINTS] || 0) !== newOrder.total_price) {
                    toast.info('주문정보 또는 포인트/쿠폰에 변동이 있습니다. 주문 내용을 다시 확인 후 시도해주세요.');
                    return;
                }
                setOrder(newOrder);
                setValues(oldValues => {
                    const newValues = JSON.parse(JSON.stringify(oldValues));
                    newValues[KEY_PG] = res.data.order[KEY_PG];
                    newValues[KEY_PAY_METHOD] = res.data.order[KEY_PAY_METHOD];
                    newValues[KEY_RECEIVER_NAME] = res.data.order[KEY_RECEIVER_NAME];
                    newValues[KEY_DELIVERY_MEMO] = res.data.order[KEY_DELIVERY_MEMO];
                    newValues[KEY_RECEIVER_PHONE_NUMBER] = res.data.order[KEY_RECEIVER_PHONE_NUMBER];
                    newValues[KEY_ORDERER_PHONE_NUMBER] = res.data.order[KEY_ORDERER_PHONE_NUMBER];
                    newValues[KEY_ORDERER_NAME] = res.data.order[KEY_ORDERER_NAME];
                    return newValues;
                });
                return res.data;
            } else {
                toast.info(res.data.display_message || 'A temporary error has occurred. Please try again later.');
            }
        } catch (e) {
            captureException(e);
            toast.info('A temporary error has occurred. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const buildPortOneRequestBody = async (validationResult) => {
        if (!validationResult) {
            return null;
        }

        const isFree = validationResult.total_price === 0;
        if (isFree) {
            return null;
        }
        const merchant_uid = validationResult.merchant_uid;

        let orderName = validationResult.option_count_list && validationResult.option_count_list.length > 0
            ?
            (
                validationResult.option_count_list[0].product.options.length > 1
                    ? `${validationResult.option_count_list[0].product.name}__${validationResult.option_count_list[0].name}`
                    : validationResult.option_count_list[0].product.name
            )
            : `HEMEKO Order (${ordNum})`;

        const domain = window.location.host.split('/')[0];
        const requestBody = {
            merchant_details: {
                name: "HEMEKO",
                logo: "images/v184_135.png",
                back_url: `https://${domain}/store/orders/${ordNum}/checkout`,
                promo_code: "p-shop350",
                promo_discount: 0.0,
                shipping_charges: 0.0
            },
            billing_details: {
                // billing_name: "Test mark",
                billing_email: values[KEY_OVERSEAS_EMAIL],
                // billing_phone: "9998878788",
                billing_address: {
                    // city: "Cambridge",
                    country_code: values[KEY_ADDRESS_COUNTRY],
                    // locale: "en",
                    // line_1: "address",
                    // line_2: "address_2",
                    // postal_code: "400202",
                    // state: "MA"
                }
            },
            shipping_details: {
                shipping_name: `${values[KEY_OVERSEAS_FIRST_NAME]} ${values[KEY_OVERSEAS_LAST_NAME]}`,
                shipping_email: values[KEY_OVERSEAS_EMAIL],
                shipping_phone: values[KEY_OVERSEAS_PHONE_COUNTRY_CODE] + values[KEY_OVERSEAS_PHONE_NUMBER],
                shipping_address: {
                    city: values[KEY_ADDRESS_CITY],
                    country_code: values[KEY_ADDRESS_COUNTRY],
                    locale: locale,
                    line_1: values[KEY_ADDRESS_LINE_1],
                    line_2: values[KEY_ADDRESS_LINE_2],
                    postal_code: values[KEY_ADDRESS_ZIP_CODE],
                    state: getStateCodeFromFullName(values[KEY_ADDRESS_STATE]) || "",
                }
            },
            merchant_order_id: validationResult.order.merchant_uid,
            signature_hash: validationResult.signature_hash,
            amount: priceToCurrencyPrice(validationResult.total_price, validationResult.currency),
            currency: validationResult.currency,
            environment: SERVICE_ENV === 'prod' ? 'live' : 'sandbox',
            description: orderName,
            country_code: validationResult.country_code,
            expiry_hours: 1,
            is_checkout_embed: false,
            show_back_button: false,
            show_shipping_details: false,
            default_guest_checkout: false,
            show_items: false,
            success_url: `https://${domain}/store/global-orders/${ordNum}/success`,
            failure_url: `https://${domain}/store/global-orders/${ordNum}/fail`
        };
        return requestBody;
    };
    /*
        const requestBody = {
            merchant_details: {
                name: "HEMEKO",
                // logo: "https://www.hemekolab.com/favicon-v2.ico",
                logo: "images/v184_135.png",
                back_url: `https://${domain}/store/orders/${ordNum}/checkout`,
                promo_code:"p-shop350",
                promo_discount:0.0,
                shipping_charges:0.0,
            },
            // billing_details: {
            //     // billing_name: `${values[KEY_BILLING_INFO_FIRST_NAME]} ${values[KEY_BILLING_INFO_LAST_NAME]}`,
            //     billing_email: values[KEY_OVERSEAS_EMAIL],
            //     // billing_phone: values[KEY_BILLING_INFO_PHONE_COUNTRY_CODE] + values[KEY_BILLING_INFO_PHONE_NUMBER],
            //     billing_address: {
            //         // city: values[KEY_BILLING_INFO_CITY],
            //         country_code: values[KEY_ADDRESS_COUNTRY],
            //         // locale: locale,
            //         // line_1: values[KEY_BILLING_INFO_LINE_1],
            //         // line_2: values[KEY_BILLING_INFO_LINE_2],
            //         // postal_code: values[KEY_BILLING_INFO_ZIP_CODE],
            //         // state: getStateCodeFromFullName(values[KEY_BILLING_INFO_STATE]),
            //     }
            // },
            billing_details: {
                billing_name: "Test mark",
                billing_email: "markweins@gmail.com",
                billing_phone: "9998878788",
                billing_address: {
                    city: "Cambridge",
                    country_code: "US",
                    locale: "en",
                    line_1: "address",
                    line_2: "address_2",
                    postal_code: "400202",
                    state: "MA"
                }
            },
            shipping_details: {
                shipping_name: `${values[KEY_OVERSEAS_FIRST_NAME]} ${values[KEY_OVERSEAS_LAST_NAME]}`,
                shipping_email: values[KEY_OVERSEAS_EMAIL],
                shipping_phone: values[KEY_OVERSEAS_PHONE_COUNTRY_CODE] + values[KEY_OVERSEAS_PHONE_NUMBER],
                shipping_address: {
                    city: values[KEY_ADDRESS_CITY],
                    country_code: values[KEY_ADDRESS_COUNTRY],
                    locale: "en",
                    line_1: values[KEY_ADDRESS_LINE_1],
                    line_2: values[KEY_ADDRESS_LINE_2],
                    postal_code: values[KEY_ADDRESS_ZIP_CODE],
                    state: getStateCodeFromFullName(values[KEY_ADDRESS_STATE]),
                }
            },
            merchant_order_id: order.order.merchant_uid,
            signature_hash: order.signature_hash,
            amount: priceToCurrencyPrice(validationResult.total_price, order.currency),
            currency: order.currency,
            environment: "sandbox",
            description: orderName,
            country_code: "US",
            expiry_hours: 1,
            is_checkout_embed: false,
            show_back_button: true,
            show_shipping_details: false,
            default_guest_checkout: true,
            show_items: false,
            success_url: `https://${domain}/store/global-orders/${ordNum}/success`,
            failure_url: `https://${domain}/store/orders/${ordNum}/fail`
        };
     */
    const loadPortOnePaymentModal = async () => {
        if (!window.PortOne) {
            toast.info('Failed to load payment gateway. Please try again later.');
            return;
        }
        const validationResult = await validateOrder();
        if (!validationResult) {
            return;
        }

        const isFree = validationResult.total_price === 0;

        if (!isFree) {
            const portOneRequestBody = await buildPortOneRequestBody(validationResult);
            if (!portOneRequestBody) {
                toast.info('Failed to validate order. Please check the input.');
                return;
            }
            setIsPaypalLoaded(true);
            console.log('validationResult', validationResult);
            console.log('PORT_ONE_KEY', PORT_ONE_KEY);
            console.log('order', order);
            console.log('iamportRequestBody', portOneRequestBody);
            try {
                const portOne = new window.PortOne({
                    portOneKey: PORT_ONE_KEY,
                    jwtToken: order.port_one_jwt_token
                })
                portOne.checkoutService.checkout(portOneRequestBody);
                console.log('load ui');
            } catch {
                console.log('no load ui');
                return
            }
        } else {
            router.push({
                pathname: getSuccessRoute(ordNum, router.locale),
                query: {
                    imp_uid: validationResult.imp_uid, merchant_uid: validationResult.order.merchant_uid, imp_success: true,
                }
            })
        }
    };

    const finalPrice = order.total_price_without_point - (values[KEY_USED_POINTS] || 0);

    const onSubmit = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageSubmitClick', {
            isReservation,
            isGift,
        });

        if (!values[KEY_AGREEMENT_CHECKED]) {
            alert(t('CheckoutInfo.agreementRequired'));
            return;
        }

        handleSubmit();

        const checkoutValidator = async () => {
            const errors = await validateForm();
            if (Object.values(errors).filter(e => !!e).length > 0) {
                for (const errorKey in errors) {
                    if (errors[errorKey]) {
                        captureMessage(`checkout submit error: ${errors[errorKey]}`);
                    }
                }
                toast.info('There is an error in the input. Please enter the order information correctly.');
                return false;
            }
            return true;
        }

        const isValid = await checkoutValidator();
        if (isValid) {
            loadPortOnePaymentModal();
        }
    };

    const onLoginClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageLoginClick');
    };


    return (
        <>
            <PortOneScript/>
            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                <TitleBar title={t('CheckoutInfo.orderPayment')} isBack close={() => router.back()}/>
            </div>
            <div className={classNames(styles.container, utilStyles.pageContainer)}>
                <div className={styles.infoSection}>
                    {
                        !user &&
                        <div className={styles.section}>
                            <span className={styles.title}>{t('CheckoutInfo.login')}</span>
                            <div className={styles.selfVerificationHelperText}>
                                <span>{t('CheckoutInfo.loginBenefitMessage')}</span>
                            </div>
                            <div className={styles.selfVerificationButtonWrapper}>
                                <Link href={{
                                    pathname: loginRoute,
                                    query: {
                                        redirect: encodeURIComponent(router.asPath),
                                    },
                                }}>
                                    <a>
                                        <FullButton fontSize={15} title={t('CheckoutInfo.quickLogin')} height={44}
                                                    onClick={onLoginClick}/>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        isGift
                            ?
                            <>
                                <CheckoutUserInfoSection
                                    title={t('CheckoutInfo.sender')}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    verificationNumber={verificationNumber}
                                    setVerificationNumber={setVerificationNumber}
                                    formik={formik}
                                    isVerificationRequired={isVerificationRequired}
                                />
                                <div className={utilStyles.mobileBorder}/>
                                <CheckoutOrderProductSection
                                    order={order}
                                    selectedCoupons={selectedCoupons}
                                />
                                <div className={utilStyles.mobileBorder}/>
                                <CheckoutGiftReceiverSection
                                    formik={formik}
                                />
                                <div className={utilStyles.mobileBorder}/>
                            </>
                            :
                            <>
                                <CheckoutAddressInfoSectionGlobal
                                    isLoading={isLoading}
                                    formik={formik}
                                    isPaypalLoaded={isPaypalLoaded}
                                />
                                <div className={utilStyles.mobileBorder}/>
                                <CheckoutOrderProductSection
                                    order={order}
                                    selectedCoupons={selectedCoupons}
                                />
                                <div className={utilStyles.mobileBorder}/>
                            </>
                    }
                    {
                        !!user &&
                        <>
                            <CheckoutCouponInfoSection
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                formik={formik}
                                order={order}
                                setOrder={setOrder}
                                initialCoupon={getInitialValues()[KEY_SELECTED_COUPONS]}
                                selectedCoupons={selectedCoupons}
                            />
                            <div className={utilStyles.mobileBorder}/>
                        </>
                    }
                </div>
                <div className={styles.confirmSection}>
                    <div className={styles.sideBarContainer}>
                        <div className={styles.infoContainer}>
                            <CheckoutPriceInfoSection
                                formik={formik}
                                order={order}
                                finalPrice={finalPrice}
                            />
                            <div className={utilStyles.mobileBorder}/>
                            <CheckoutAgreementSection
                                formik={formik}
                                finalPrice={finalPrice}
                                isReservation={isReservation}
                                isPaypalLoaded={isPaypalLoaded}
                                countryCode={countryCode}
                            />
                        </div>
                        <div className={styles.checkoutButtonContainer}>
                            <FullButton
                                fontSize={16}
                                onClick={onSubmit}
                                height={48}
                            >
                                {
                                    isOrderValidating ?
                                        <Loading/> :
                                        finalPrice === 0 ?
                                            t('CheckoutInfo.completeOrder') :
                                            `${formattedPrice(finalPrice, currency)} ${t(`common:currency.${currency}`)} ${t('CheckoutInfo.checkout')}`
                                }
                            </FullButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default USCheckoutInfo
