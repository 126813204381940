import {useRouter} from "next/router";
import {useContext, useEffect, useMemo, useState} from "react";
import UserContext from "context/AuthContext";
import {useFormik} from "formik";
import * as yup from "yup";
import moment from "moment-timezone";
import {getSuccessRoute, IAMPORT_STORE_ID, loginRoute, SITE_URL} from "common/const";
import {Axios} from "api";
import {getLocalStorageCountryCode, getLoggingDataFromOptionCount, numberWithComma} from "common/utils";
import {toast} from "react-toastify";
import {captureException, captureMessage} from "@sentry/nextjs";
import utilStyles from "styles/utils.module.scss";
import IamportScript from "components/IamportScript";
import classNames from "classnames";
import TitleBar from "components/layout/TitleBar";
import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import Link from "next/link";
import FullButton from "components/buttons/FullButton";
import CheckoutUserInfoSection from "components/sections/checkout/CheckoutUserInfoSection";
import CheckoutOrderProductSection from "components/sections/checkout/CheckoutOrderProductSection";
import CheckoutGiftReceiverSection from "components/sections/checkout/CheckoutGiftReceiverSection";
import CheckoutAddressInfoSection from "components/sections/checkout/CheckoutAddressInfoSection";
import CheckoutCouponInfoSection from "components/sections/checkout/CheckoutCouponInfoSection";
import CheckoutPriceInfoSection from "components/sections/checkout/CheckoutPriceInfoSection";
import CheckoutPaymentSelector from "components/input/CheckoutPaymentSelector";
import CheckoutAgreementSection from "components/sections/checkout/agreement/CheckoutAgreementSection";
import {
    KEY_ADDRESS_COUNTRY,
    KEY_ADDRESS_ID,
    KEY_AGREEMENT_CHECKED,
    KEY_DELIVERY_MEMO,
    KEY_DETAIL_ADDRESS,
    KEY_GIFT_RECEIVER_NAME,
    KEY_IS_ESCROW,
    KEY_ORDERER_NAME,
    KEY_ORDERER_PHONE_NUMBER,
    KEY_PAY_METHOD,
    KEY_PG,
    KEY_POSTAL_CODE,
    KEY_RECEIVER_NAME,
    KEY_RECEIVER_PHONE_NUMBER,
    KEY_ROUGH_ADDRESS,
    KEY_SELECTED_COUPONS,
    KEY_SUGGEST_BEST_COUPON,
    KEY_USED_POINTS,
    KEY_VERIFIED_PHONE_NUMBER
} from "pages/store/orders/[ordNum]/checkout"
import Loading from "components/Loading";
import useTranslation from "next-translate/useTranslation";
import CheckoutReservationSelectedDate from "components/sections/checkout/CheckoutReservationSelectedDate";

const KRCheckoutInfo = (props) => {
    const {order, setOrder, isVerificationRequired} = props;
    const ordNum = order ? order.order.order_number : '';
    const {t} = useTranslation('sections-checkout-CheckoutInfo');
    const countryCode = getLocalStorageCountryCode() ?? 'KR';
    const isGift = order ? order.order.is_gift : false;
    const isReservation = order ? order.order.is_reservation : false;
    // 헤메티켓이거나 회원권 구매일 경우
    const isShopPurchase = isReservation || order.order?.extra_data?.reservation_selected_date;

    const router = useRouter();

    const [isLoading, setIsLoading] = useState(false);

    const userContext = useContext(UserContext);
    const user = userContext.user;

    const [verificationNumber, setVerificationNumber] = useState(undefined);
    if (!order) {
        return <div></div>
    }
    const currency = useMemo(() => {
        return order?.currency || 'KRW';
    }, [order])

    const getInitialValues = () => {
        if (order && order.order && order.order[KEY_POSTAL_CODE]) {
            return {
                [KEY_POSTAL_CODE]: order.order[KEY_POSTAL_CODE],
                [KEY_ROUGH_ADDRESS]: order.order[KEY_ROUGH_ADDRESS],
                [KEY_DETAIL_ADDRESS]: order.order[KEY_DETAIL_ADDRESS],
                [KEY_RECEIVER_PHONE_NUMBER]: order.order[KEY_RECEIVER_PHONE_NUMBER],
                [KEY_RECEIVER_NAME]: order.order[KEY_RECEIVER_NAME],
                [KEY_DELIVERY_MEMO]: order.order[KEY_DELIVERY_MEMO],
                [KEY_PAY_METHOD]: order.order[KEY_PAY_METHOD],
                [KEY_PG]: order.order[KEY_PG],
                [KEY_SELECTED_COUPONS]: order[KEY_SELECTED_COUPONS],
                [KEY_USED_POINTS]: '',
                [KEY_ADDRESS_ID]: order.order[KEY_ADDRESS_ID],
                [KEY_ORDERER_NAME]: order.order[KEY_ORDERER_NAME],
                [KEY_ORDERER_PHONE_NUMBER]: order.order[KEY_ORDERER_PHONE_NUMBER],
                [KEY_AGREEMENT_CHECKED]: false,
                [KEY_IS_ESCROW]: false,
                [KEY_GIFT_RECEIVER_NAME]: '',
                [KEY_VERIFIED_PHONE_NUMBER]: '',

            }
        } else {
            let defaultAddress = {};
            if (user) {
                if (!isGift && user.addresses && user.addresses.length > 0) {
                    const userDefaultAddress = user.addresses.filter(e => e.id === user.default_address_id)[0];
                    if (userDefaultAddress) {
                        defaultAddress = userDefaultAddress;
                    } else {
                        defaultAddress = user.addresses[0];
                    }
                } else {
                    defaultAddress = {};
                }
            }
            return {
                [KEY_ADDRESS_ID]: defaultAddress[KEY_ADDRESS_ID] || '',
                [KEY_POSTAL_CODE]: defaultAddress[KEY_POSTAL_CODE] || '',
                [KEY_ROUGH_ADDRESS]: defaultAddress[KEY_ROUGH_ADDRESS] || '',
                [KEY_DETAIL_ADDRESS]: defaultAddress[KEY_DETAIL_ADDRESS] || '',
                [KEY_RECEIVER_PHONE_NUMBER]: defaultAddress.phone_number || '',
                [KEY_RECEIVER_NAME]: defaultAddress[KEY_RECEIVER_NAME] || '',
                [KEY_DELIVERY_MEMO]: defaultAddress.memo || '',
                [KEY_PAY_METHOD]: order ? order.available_pay_methods[0].pay_method : '',
                [KEY_PG]: order ? order.available_pay_methods[0].pg : '',
                [KEY_SELECTED_COUPONS]: order ? order[KEY_SELECTED_COUPONS] : [],
                [KEY_USED_POINTS]: '',
                [KEY_ADDRESS_ID]: isGift ? null : (defaultAddress.id || null),
                [KEY_ORDERER_NAME]: '',
                [KEY_ORDERER_PHONE_NUMBER]: user ? user.sns_phone_number : '',
                [KEY_AGREEMENT_CHECKED]: false,
                [KEY_IS_ESCROW]: false,
                [KEY_GIFT_RECEIVER_NAME]: '',
                [KEY_VERIFIED_PHONE_NUMBER]: '',
            }
        }
    };

    const isDeliveryNotRequired = isGift || isReservation || order.order.extra_data?.is_delivery_not_required;


    const formik = useFormik({
        enableReinitialize: false,
        initialValues: getInitialValues(),
        validationSchema: yup.object({
            [KEY_DELIVERY_MEMO]: yup.string().max(50),
            [KEY_PAY_METHOD]: yup.string().nullable(true).oneOf(order.available_pay_methods.map(e => e.pay_method).concat([null])),
            [KEY_PG]: yup.string().nullable(true).oneOf(order.available_pay_methods.map(e => e.pg).concat([null])),
            [KEY_POSTAL_CODE]: (!isDeliveryNotRequired ? yup.string().required().matches(/^\d*$/).min(4).max(6) : yup.string()),
            [KEY_ROUGH_ADDRESS]: (!isDeliveryNotRequired ? yup.string().required() : yup.string()),
            [KEY_DETAIL_ADDRESS]: (!isDeliveryNotRequired ? yup.string().max(40).required() : yup.string()),
            [KEY_RECEIVER_PHONE_NUMBER]: (!isDeliveryNotRequired ? yup.string().required().max(13) : yup.string()),
            [KEY_RECEIVER_NAME]: (!isDeliveryNotRequired ? yup.string().required().max(64) : yup.string()),
            [KEY_ADDRESS_ID]: yup.number().nullable().min(1),
            [KEY_ORDERER_NAME]: !user ? yup.string().required().max(64) : yup.string(),
            [KEY_ORDERER_PHONE_NUMBER]: !user ? yup.string().required().max(13) : yup.string(),
            [KEY_SELECTED_COUPONS]: yup.array(),
            [KEY_USED_POINTS]: yup.number().min(0),
            [KEY_AGREEMENT_CHECKED]: yup.bool().required().oneOf([true]),
            [KEY_GIFT_RECEIVER_NAME]: (isGift ? yup.string().required('받으시는 분 이름은 필수 사항입니다.').max(10, '이름은 최대 10자까지 가능합니다.') : yup.string()),
            [KEY_ADDRESS_COUNTRY]: yup.string()
        }),
        onSubmit: values => {
            if (currency === 'USD') {
                return;
            }

            if (
                (!values[KEY_ORDERER_PHONE_NUMBER] || !values[KEY_VERIFIED_PHONE_NUMBER]) ||
                (values[KEY_ORDERER_PHONE_NUMBER].split('-').join('') !== values[KEY_VERIFIED_PHONE_NUMBER].split('-').join(''))
            ) {
                alert("최초 주문 시 휴대폰 번호 인증이 필요합니다. 인증을 완료해주세요.");
                return;
            }
            makePayment(values);
        },
    })
    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;


    const selectedCoupons = useMemo(() => {
        return values[KEY_SELECTED_COUPONS] || [];
    }, [values]);

    useEffect(() => {
        if (userContext.user && (userContext.user.name || userContext.user.phone_number)) {
            setValues(oldValues => {
                const newValues = JSON.parse(JSON.stringify(oldValues));
                if (userContext.user.name) newValues[KEY_ORDERER_NAME] = userContext.user.name;
                if (userContext.user.phone_number) {
                    newValues[KEY_ORDERER_PHONE_NUMBER] = userContext.user.phone_number;
                    newValues[KEY_VERIFIED_PHONE_NUMBER] = userContext.user.phone_number;
                }
                return newValues;
            })
        }
    }, [userContext.user]);

    const redirectURL = `${getSuccessRoute(ordNum)}`;
    const failURL = `/store/orders/${ordNum}/fail`;

    const finalPrice = order.total_price_without_point - (values[KEY_USED_POINTS] || 0);

    const makePayment = async (values) => {
        if (!values[KEY_USED_POINTS]) {
            values[KEY_USED_POINTS] = 0;
        }
        try {
            jQuery
        } catch {
            return
        }
        let merchant_uid;
        let vbank_due_date = null;

        setIsLoading(true);
        let isFree = false;
        try {
            const data = JSON.parse(JSON.stringify(values));
            data[KEY_SUGGEST_BEST_COUPON] = false;
            const res = await Axios.patch(`v1/store/orders/${ordNum}/info`, data);
            if (res.status < 400) {
                const newOrder = res.data;
                const optionCountList = newOrder.option_count_list;
                const totalPrice = newOrder.total_price;
                const totalCount = optionCountList.reduce((acc, cur) => acc + cur.count, 0);
                const totalUniqueOptionCount = optionCountList.length;
                const isLoginPurchase = Boolean(user);
                typeof mixpanel !== 'undefined' && mixpanel.track('ConfirmCheckout',
                    {
                        order_number: ordNum,
                        is_login_purchase: isLoginPurchase,
                        totalPrice,
                        totalCount,
                        totalUniqueOptionCount,
                    }
                );
                for (const optionCount of optionCountList) {
                    const loggingData = getLoggingDataFromOptionCount(optionCount, ordNum, isLoginPurchase);
                    typeof mixpanel !== 'undefined' && mixpanel.track('ConfirmCheckoutOrderOption', loggingData);
                }
                if (0 < (values[KEY_USED_POINTS] || 0) && (values[KEY_USED_POINTS] || 0) < order.min_usable_points) {
                    alert(`포인트는 ${order.min_usable_points}p 이상만 사용할 수 있습니다.`);
                    return;
                }
                if (order.total_price_without_point - (values[KEY_USED_POINTS] || 0) !== newOrder.total_price) {
                    alert('주문정보 또는 포인트/쿠폰에 변동이 있습니다. 주문 내용을 다시 확인 후 시도해주세요.');
                    return;
                }
                if (newOrder.total_price === 0) isFree = true;
                merchant_uid = res.data.order['merchant_uid'];
                vbank_due_date = res.data['vbank_due'];
                setOrder(newOrder);
                setValues(oldValues => {
                    const newValues = JSON.parse(JSON.stringify(oldValues));
                    newValues[KEY_PG] = res.data.order[KEY_PG];
                    newValues[KEY_PAY_METHOD] = res.data.order[KEY_PAY_METHOD];
                    newValues[KEY_POSTAL_CODE] = res.data.order[KEY_POSTAL_CODE];
                    newValues[KEY_DETAIL_ADDRESS] = res.data.order[KEY_DETAIL_ADDRESS];
                    newValues[KEY_RECEIVER_NAME] = res.data.order[KEY_RECEIVER_NAME];
                    newValues[KEY_ROUGH_ADDRESS] = res.data.order[KEY_ROUGH_ADDRESS];
                    newValues[KEY_DELIVERY_MEMO] = res.data.order[KEY_DELIVERY_MEMO];
                    newValues[KEY_RECEIVER_PHONE_NUMBER] = res.data.order[KEY_RECEIVER_PHONE_NUMBER];
                    newValues[KEY_ADDRESS_ID] = res.data.order[KEY_ADDRESS_ID];
                    newValues[KEY_ORDERER_PHONE_NUMBER] = res.data.order[KEY_ORDERER_PHONE_NUMBER];
                    newValues[KEY_ORDERER_NAME] = res.data.order[KEY_ORDERER_NAME];
                    return newValues;
                });
            } else {
                toast.info(res.data.display_message || '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                // router.replace({
                //     pathname: getFailRoute(ordNum),
                //     query: {error_msg: res.data.display_message},
                // })
            }
        } catch (e) {
            captureException(e);
            toast.info('일시적이 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }

        if (!merchant_uid) {
            return;
        }

        if (!isFree) {
            vbank_due_date =
                moment.tz(vbank_due_date * 1000, "Asia/Seoul").format('YYYYMMDDHHmm');

            let orderName = order.option_count_list && order.option_count_list.length > 0
                ?
                (
                    order.option_count_list[0].product.options.length > 1
                        ? `${order.option_count_list[0].product.name}__${order.option_count_list[0].name}`
                        : order.option_count_list[0].product.name
                )
                : `헤메코 상품 주문 (주문번호: ${ordNum})`;

            if (order.option_count_list && order.option_count_list.length > 0 && isReservation) {
                orderName = `[${order.option_count_list[0].product.brand.name}] ${orderName}`
            }

            const iamportRequestBody = {
                pg: values.pg,
                pay_method: values.pay_method,
                name: orderName,
                // merchant_uid: `m${oid}_${Date.now()}`, //상점에서 생성한 고유 주문번호
                merchant_uid: merchant_uid, //상점에서 생성한 고유 주문번호
                amount: order.total_price_without_point - (values[KEY_USED_POINTS] || 0), // 위에서 newOrder.total_price 랑 비교하긴 함.
                buyer_name: values[KEY_ORDERER_NAME],
                buyer_email: '',
                buyer_tel: values[KEY_ORDERER_PHONE_NUMBER],
                buyer_addr: '',
                escrow: values[KEY_IS_ESCROW],
                buyer_postcode: '',
                m_redirect_url: `${SITE_URL}${redirectURL}`,
                notice_url: 'https://api-staging.hemeko.com/fakeurl', // TODO: Remove on prod env
                vbank_due: vbank_due_date,
            };

            if (values.pg === 'naverpay') {
                iamportRequestBody['naverProducts'] = order.option_count_list.map(x => {
                    const productName = x.product.options.length > 1
                        ? `${x.product.name}__${x.name}`
                        : x.product.name;

                    return {
                        "categoryType": "PRODUCT",
                        "categoryId": "GENERAL",
                        "uid": `P${String(x.id).padStart(12, '0')}`,
                        "name": productName,
                        "count": x.count
                    }
                });
            }

            try {
                window.IMP.request_pay
            } catch {
                return
            }  // Make sure window.IMP is set
            window.IMP.init(IAMPORT_STORE_ID);
            window.IMP.request_pay(iamportRequestBody, (res) => {
                if (res.success) {
                    router.push({
                        pathname: redirectURL,
                        query: {
                            imp_uid: res.imp_uid, merchant_uid: res.merchant_uid, imp_success: true, country_code: 'KR',
                        }
                    });
                } else {
                    alert((res.error_code ? `(${res.error_code}) ` : '') + res.error_msg);
                }
            });
        } else {
            router.push({
                pathname: redirectURL,
                query: {
                    imp_uid: '', merchant_uid: merchant_uid, imp_success: true,
                }
            })
        }
    };

    const onSubmit = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageSubmitClick', {
            isReservation,
            isGift,
        });


        if (!values[KEY_AGREEMENT_CHECKED]) {
            alert(t('CheckoutInfo.agreementRequired'));
            return;
        }


        const addressValidator = () => {
            if ((!isDeliveryNotRequired && !values[KEY_DETAIL_ADDRESS]) || Object.values(errors).filter(e => !!e).length > 0) {
                if (Object.values(errors).filter(e => !!e).length > 0) {
                    for (const errorKey in errors) {
                        if (errors[errorKey]) {
                            captureMessage(`checkout submit error: ${errors[errorKey]}`);
                        }
                    }
                    console.log('errors', errors);
                }
                toast.info(t('CheckoutInfo.invalidInput'));
                return false;
            }
            return true;
        }

        const isValid = addressValidator();
        if (!isValid) {
            return;
        }

        handleSubmit();
    };

    const onLoginClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageLoginClick');
    };


    return (
        <>
            <IamportScript/>
            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                <TitleBar title={t('CheckoutInfo.orderPayment')} isBack close={() => router.back()}/>
            </div>
            <div className={classNames(styles.container, utilStyles.pageContainer)}>
                <div className={styles.infoSection}>
                    {
                        !user &&
                        <div className={styles.section}>
                            <span className={styles.title}>{t('CheckoutInfo.login')}</span>
                            <div className={styles.selfVerificationHelperText}>
                                <span>{t('CheckoutInfo.loginBenefitMessage')}</span>
                            </div>
                            <div className={styles.selfVerificationButtonWrapper}>
                                <Link href={{
                                    pathname: loginRoute,
                                    query: {
                                        redirect: encodeURIComponent(router.asPath),
                                    },
                                }}>
                                    <a>
                                        <FullButton fontSize={15} title={t('CheckoutInfo.quickLogin')} height={44}
                                                    onClick={onLoginClick}/>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        isReservation
                            ?
                            <>
                                <CheckoutUserInfoSection
                                    title={t('CheckoutInfo.reservationInfo')}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    verificationNumber={verificationNumber}
                                    setVerificationNumber={setVerificationNumber}
                                    formik={formik}
                                    isVerificationRequired={isVerificationRequired}

                                />
                                <div className={utilStyles.mobileBorder}/>
                                <CheckoutOrderProductSection
                                    title={t('CheckoutInfo.reservationShop')}
                                    order={order}
                                    selectedCoupons={selectedCoupons}
                                    isReservation
                                />
                                <div className={utilStyles.mobileBorder}/>
                            </> :
                            isGift
                                ?
                                <>
                                    <CheckoutUserInfoSection
                                        title={t('CheckoutInfo.sender')}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        verificationNumber={verificationNumber}
                                        setVerificationNumber={setVerificationNumber}
                                        formik={formik}
                                        isVerificationRequired={isVerificationRequired}
                                    />
                                    <div className={utilStyles.mobileBorder}/>
                                    <CheckoutOrderProductSection
                                        order={order}
                                        selectedCoupons={selectedCoupons}
                                    />
                                    <div className={utilStyles.mobileBorder}/>
                                    <CheckoutGiftReceiverSection
                                        formik={formik}
                                    />
                                    <div className={utilStyles.mobileBorder}/>
                                </>
                                :
                                <>
                                    <CheckoutUserInfoSection
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        verificationNumber={verificationNumber}
                                        setVerificationNumber={setVerificationNumber}
                                        formik={formik}
                                        isVerificationRequired={isVerificationRequired}
                                    />
                                    <div className={utilStyles.mobileBorder}/>
                                    {
                                        !order.order.extra_data?.is_delivery_not_required &&
                                        <>
                                            <CheckoutAddressInfoSection
                                                isLoading={isLoading}
                                                formik={formik}
                                            />
                                            <div className={utilStyles.mobileBorder}/>
                                        </>
                                    }
                                    <CheckoutOrderProductSection
                                        order={order}
                                        selectedCoupons={selectedCoupons}
                                    />
                                    <div className={utilStyles.mobileBorder}/>
                                    {
                                        order.order?.extra_data?.reservation_selected_date &&
                                        <>
                                            <CheckoutReservationSelectedDate
                                                reservationSelectedDate={order.order.extra_data.reservation_selected_date}
                                            />
                                            <div className={utilStyles.mobileBorder}/>
                                        </>
                                    }
                                </>
                    }
                    {
                        !!user && !isShopPurchase &&
                        <>
                            <CheckoutCouponInfoSection
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                formik={formik}
                                order={order}
                                setOrder={setOrder}
                                initialCoupons={getInitialValues()[KEY_SELECTED_COUPONS]}
                                selectedCoupons={selectedCoupons}
                                user={user}
                            />
                            <div className={utilStyles.mobileBorder}/>
                        </>
                    }
                </div>
                <div className={styles.confirmSection}>
                    <div className={styles.sideBarContainer}>
                        <div className={styles.infoContainer}>
                            <CheckoutPriceInfoSection
                                formik={formik}
                                order={order}
                                finalPrice={finalPrice}
                            />
                            <div className={utilStyles.mobileBorder}/>
                            {
                                finalPrice !== 0 && currency === 'KRW' &&
                                <>
                                    <div className={styles.section}>
                                        <span className={styles.title}>{t('CheckoutInfo.paymentMethod')}</span>
                                        <CheckoutPaymentSelector
                                            compact
                                            payMethod={values.pay_method}
                                            isEscrow={values[KEY_IS_ESCROW]}
                                            methods={order.available_pay_methods}
                                            onSelect={(pg, payMethod, isEscrow) => {
                                                setFieldValue(KEY_PAY_METHOD, payMethod);
                                                setFieldValue(KEY_PG, pg);
                                                setFieldValue(KEY_IS_ESCROW, isEscrow || false);
                                            }}
                                            isGift={isGift}
                                            isReservation={isReservation}
                                        />
                                    </div>
                                    <div className={utilStyles.mobileBorder}/>
                                </>
                            }
                            <CheckoutAgreementSection
                                formik={formik}
                                finalPrice={finalPrice}
                                isReservation={isShopPurchase}
                                countryCode={countryCode}
                                order={order}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.checkoutButtonContainer}>
                    <FullButton
                        fontSize={16}
                        height={48}
                        onClick={onSubmit}
                    >
                        {
                            finalPrice === 0 ?
                                t('CheckoutInfo.completeOrder') :
                                `${numberWithComma(finalPrice)} ${t(`common:currency.${currency}`)} ${t('CheckoutInfo.checkout')}`
                        }
                    </FullButton>
                </div>
            </div>
        </>
    );
}

export default KRCheckoutInfo
