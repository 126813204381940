import {createContext, useContext, useEffect, useState} from "react";
import UserContext from "context/AuthContext";
import ModalContext from "context/ModalContext";
import {
    checkIfOpenAllowed,
    checkSessionStorageFlagIsTrue,
    hideForXMinutes,
    saveSessionStorageFlagTrue
} from "common/modalHelper";
import SelectCountryModal, {
    STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL,
    STORAGE_KEY_COUNTRY_MODAL_SKIPPED
} from "components/modal/SelectCountryModal";
import {useRouter} from "next/router";
import {COUNTRY_CODE} from "common/const";
import {getLocalStorageCountryCode, isApp} from "common/utils";
import Cookies from "js-cookie";
import {COUNTRY_CODE_TO_BASE_URL} from "common/country_list";
import {redirectCountryToMain} from "common/redirect";
import {KOREA_COUNTRY_CODE} from "common/i18n";
import {getCountryCodeByUserIp} from "api/localization/i18n";

const I18nContext = createContext(undefined);

export let i18nContextRef = {};

export default I18nContext;

const I18nContextWrapper = ({children}) => {
    const context = useContext(I18nContext);

    useEffect(() => {
        i18nContextRef.current = context;
    }, []);

    useEffect(() => {
        i18nContextRef.current = context;
    }, [context]);

    return (
        <>
            {children}
        </>
    )
}


export const I18nContextProvider = ({children}) => {
    const [countryCode, setCountryCode] = useState(getLocalStorageCountryCode() || COUNTRY_CODE);
    const user = useContext(UserContext).user;
    const {openModal, closeModal} = useContext(ModalContext) || {};
    const {locale, query, replace} = useRouter(); // 현재 사이트 도메인이 바뀌는 걸 detect 할 방법을 찾아야 함

    const changeCountryCode = (targetCountryCode) => {
        hideForXMinutes(STORAGE_KEY_COUNTRY_MODAL_SKIPPED, 24 * 30 * 6);

        if (countryCode === targetCountryCode) {
            return;
        }

        if (typeof window === 'undefined') {
            return;
        }

        const beforeSiteUrl = COUNTRY_CODE_TO_BASE_URL[countryCode];
        const selectedBaseUrl = COUNTRY_CODE_TO_BASE_URL[targetCountryCode];
        const siteUrl = `.${window.location.host.split('.').slice(-2).join('.')}`;
        Cookies.set("NEXT_COUNTRY", targetCountryCode, {
            expires: 365, domain: siteUrl,
        });
        setCountryCode(targetCountryCode);

        if (selectedBaseUrl !== beforeSiteUrl) {
            const previousPathname = window.location.search;
            const newPathname = previousPathname ? `${previousPathname}&saveCountry=true` : '?saveCountry=true';
            window.location.href = `https://${selectedBaseUrl}${window.location.pathname}${newPathname}`;
        }
    }

    console.log('wwww', typeof window !== 'undefined' ? window : '');

    const checkCountryByIpAndOpenSelectCountryModal = async (force = false) => {
        if (isApp()) {
            return;
        }

        if (force) {
            openModal(() => {
                return (
                    <SelectCountryModal
                        close={closeModal}
                        currentCountryCode={countryCode}
                        onSubmit={changeCountryCode}
                    />
                )
            });
            return;
        }

        const flag = !checkIfOpenAllowed(STORAGE_KEY_COUNTRY_MODAL_SKIPPED);
        if (flag) {
            return;
        }

        if (countryCode === KOREA_COUNTRY_CODE) {
            return;
        }

        const ipCountryCode = await getCountryCodeByUserIp();

        if (!ipCountryCode || !countryCode || countryCode === ipCountryCode) {
            return;
        }

        if (user) {
            return;
        }

        if (!checkIfOpenAllowed(STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL)) {
            return;
        }

        openModal(() => {
            return (
                <SelectCountryModal
                    close={closeModal}
                    currentCountryCode={countryCode}
                    onSubmit={changeCountryCode}
                />
            )
        });
    };

    const saveCountryCode = () => {

        if (query.saveCountry) {
            hideForXMinutes(STORAGE_KEY_COUNTRY_MODAL_SKIPPED, 24 * 30 * 6);
            // delete query
            const newQuery = {...query};
            delete newQuery.saveCountry;
            replace({query: newQuery});
        }
    };
    useEffect(() => {
        saveCountryCode();
    }, [query]);

    useEffect(() => {
        checkCountryByIpAndOpenSelectCountryModal();
    }, [countryCode]);


    const contextValue = {
        countryCode, checkCountryByIpAndOpenSelectCountryModal, changeCountryCode
    };

    return (
        <I18nContext.Provider value={contextValue}>
            <I18nContextWrapper>
                {children}
            </I18nContextWrapper>
        </I18nContext.Provider>
    )
}
