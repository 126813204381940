import styles from "components/input/input.module.scss";
import utilStyles from "styles/utils.module.scss";

import ArrowDownIcon from "images/icons/arrow_down_757575.svg";
import classNames from "classnames";
import {useRef, useState} from "react";
import {useOutsideAlerter} from "common/utils";

export default function TextInputDropdown (props) {
    const {value, placeholder, choices, disableOnSingleChoice} = props;
    const onChange = props.onChange || function () {};

    const [isExpanded, setIsExpanded] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setIsExpanded(false);
    });

    const disable = disableOnSingleChoice && choices.length === 1;

    return (
        <div ref={wrapperRef} style={{position: 'relative', width: '100%'}}>
            <div className={classNames(styles.input, !disable && styles.dropdownInput, isExpanded ? styles.dropdownInputActive : undefined, disable && styles.input_disabled)}
                 onClick={() => !disable && setIsExpanded(val => !val)}>
                <span className={!value ? styles.placeholder : undefined}>{value || placeholder}</span>
                <ArrowDownIcon style={{marginLeft: 24}} viewBox="0 0 20 20" height="16" width="16" />
            </div>
            {
                isExpanded &&
                <div className={styles.selectorContainer}>
                    {
                        !!choices && choices.map((e, i) => {
                            return (
                                <div key={i} className={styles.choice} onClick={(evt) => {
                                    onChange(e);
                                    evt.stopPropagation();
                                    setIsExpanded(val => !val);
                                }}>
                                    {e}
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}
