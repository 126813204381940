import styles from "components/input/checkoutPaymentSelector.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import KakaoPay from "images/icons/kakao_pay.svg";
import TossPay from "images/icons/toss_pay.svg";
import NaverPayInactive from "images/icons/naverpay_inactive.svg";
import NaverPayActive from "images/icons/naverpay_active.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";


export default function CheckoutPaymentSelector (props) {
    const {methods, onSelect, payMethod, isEscrow, compact, isGift, isReservation} = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isOdd = methods.length % 2 === 1;

    const onSelectMethod = (method) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'CheckoutPaymentSelectorSelectMethod',
            {
                pg: method.pg,
                payMethod: method.pay_method,
                escrow: method.escrow,
            }
        );
        onSelect(method.pg, method.pay_method, method.escrow);
    };

    return (
        <div className={classNames(utilStyles.flexRow, styles.container)} style={{flexFlow: !compact ? 'wrap' : undefined}}>
            {
                methods.filter((method) => (!isGift && !isReservation) || method.name !== '가상계좌').map((method, i) => {
                    const getIsActive = (i) => {
                        if (i < 0 || i >= methods.length) {
                            return false;
                        }
                        const m = methods[i];
                        return payMethod === m.pay_method && !!m.escrow === !!isEscrow;
                    }
                    const isActive = getIsActive(i);
                    const isNextActive = getIsActive(i + 1);

                    return (
                            <div className={classNames(styles.methodContainer, compact ? (isOdd ? styles.oddCompactMethodContainer : styles.compactMethodContainer) : undefined)}
                                 key={`${method.name}_${method.escrow}`}>
                                <div
                                    className={classNames(styles.button, compact ? styles.compactButton : undefined, isActive ? styles.buttonActive : undefined)}
                                    style={compact ? {
                                        borderTopRightRadius: isMobile || i === methods.length - 1 ? 5 : 0,
                                        borderBottomRightRadius: isMobile || i === methods.length - 1 ? 5 : 0,
                                        borderTopLeftRadius: isMobile || i === 0 ? 5 : 0,
                                        borderBottomLeftRadius: isMobile || i === 0 ? 5 : 0,
                                        borderLeftWidth: isMobile || isActive || i === 0 ? 1 : 0,
                                        borderRightWidth: isMobile || !isNextActive ? 1 : 0,
                                    } : {borderRadius: 5}}
                                    onClick={() => onSelectMethod(method)}>
                                    {
                                        method.name === '카카오페이' && <KakaoPay className={isActive ? styles.kakaoActive : undefined} viewBox="0 0 48 14" width={isMobile ? 48 : 57} height={isMobile ? 14 : 16}  />
                                    }
                                    {
                                        method.name === '토스페이' && <TossPay className={isActive ? styles.tossActive : undefined} viewBox="0 0 54 14" width={isMobile ? 54 : 67} height={isMobile ? 14 : 16} />
                                    }
                                    {
                                        method.name === '네이버페이' &&
                                        (
                                            isActive
                                            ?
                                                <NaverPayActive viewBox="0 0 48 19" width={isMobile ? 48 : 67} height={isMobile ? 19 : 16} />
                                            :
                                                <NaverPayInactive viewBox="0 0 48 19" width={isMobile ? 48 : 67} height={isMobile ? 19 : 16} />
                                        )
                                    }
                                    {
                                        method.name !== '토스페이' && method.name !== '카카오페이' && method.name !== '네이버페이' &&
                                        (
                                            <div style={{whiteSpace: 'pre-wrap', lineHeight: '100%', textAlign: 'center'}}>
                                                {method.name}
                                                {
                                                    method.escrow &&
                                                    <span className={styles.escrowText}>{`${isMobile ? ' ' : '\n' }(에스크로)`}</span>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                    )
                })
            }
        </div>
    );
}
