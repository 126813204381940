import styles from "components/cards/checkoutAddressCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import checkoutStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import modalStyles from "components/modal/deliveryAddressModal.module.scss";
import classNames from "classnames";
import PipeIcon from "images/icons/pipe.svg";
import {useMemo, useRef, useState} from "react";
import Modal from "components/Modal";
import AddressList, {AddressAdd, PHONE_NUMBER_REGEX} from "components/modal/DeliveryAddressModal";
import TextInput from "components/input/TextInput";
import {
    deliveryMemoChoices,
    KEY_ADDRESS_ID,
    KEY_DELIVERY_MEMO,
    KEY_DELIVERY_NAME,
    KEY_DETAIL_ADDRESS,
    KEY_ORDERER_NAME,
    KEY_ORDERER_PHONE_NUMBER,
    KEY_POSTAL_CODE,
    KEY_RECEIVER_NAME,
    KEY_RECEIVER_PHONE_NUMBER,
    KEY_ROUGH_ADDRESS,
    VALUE_SELF_ENTER
} from "pages/store/orders/[ordNum]/checkout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {findNextInputAndFocus, getLocalStorageCountryCode} from "common/utils";
import TextInputDropdown from "components/input/TextInputDropdown";
import InputError from "components/input/InputError";
import useTranslation from 'next-translate/useTranslation';

export default function CheckoutAddressCard(props) {
    const { t } = useTranslation('components-cards-CheckoutAddressCard');
    const CHECKOUT_ADDRESS_KEY_MAP = {
        [KEY_POSTAL_CODE]: KEY_POSTAL_CODE,
        [KEY_ROUGH_ADDRESS]: KEY_ROUGH_ADDRESS,
        [KEY_DETAIL_ADDRESS]: KEY_DETAIL_ADDRESS,
        [KEY_RECEIVER_PHONE_NUMBER]: KEY_RECEIVER_PHONE_NUMBER,
        [KEY_DELIVERY_NAME]: KEY_DELIVERY_NAME,
        [KEY_RECEIVER_NAME]: KEY_RECEIVER_NAME,
        [KEY_ADDRESS_ID]: KEY_ADDRESS_ID,
        [KEY_DELIVERY_MEMO]: KEY_DELIVERY_MEMO,
        [KEY_ORDERER_NAME]: KEY_ORDERER_NAME,
        [KEY_ORDERER_PHONE_NUMBER]: KEY_ORDERER_PHONE_NUMBER,
    }
    const {values, user, setFieldValue, isLoading, errors, touched, nameInputRef, setValues, isAddressListModalOpen, setIsAddressListModalOpen} = props;
    const keyMap = props.keyMap || CHECKOUT_ADDRESS_KEY_MAP;
    const onChangeAddress = props.onChangeAddress || function () {};
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const [selfEnter, setSelfEnter] = useState(!deliveryMemoChoices.includes(values[keyMap[KEY_DELIVERY_MEMO]]) && values[keyMap[KEY_DELIVERY_MEMO]] !== '');
    const onDeliveryMemoChoiceClick = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageDeliveryMemoChoiceClick', {choice: e});
        setFieldValue(keyMap[KEY_DELIVERY_MEMO], e);
        if (e === VALUE_SELF_ENTER) {
            setSelfEnter(true);
            setFieldValue(keyMap[KEY_DELIVERY_MEMO], '');
        } else {
            setFieldValue(keyMap[KEY_DELIVERY_MEMO], e);
            setSelfEnter(false);
        }
    };

    const detailAddressInputRef = useRef(null);
    const countryCode = getLocalStorageCountryCode() ?? 'KR';

    useMemo(() => {
        if (countryCode !== 'KR') {
            setFieldValue(keyMap[KEY_DELIVERY_MEMO], '');
            setSelfEnter(true);
        }
    }, [])


    return (
        <div>
            {
                isSearchModalOpen &&
                <Modal width={isMobile ? '100%' : 416} height={isMobile ? '100%' : undefined} isOpen={isSearchModalOpen}
                       close={() => setIsSearchModalOpen(false)}>
                            <div className={modalStyles.modalContainer}>
                                <AddressAdd
                                    searchOnly isClose onClose={() => setIsSearchModalOpen(false)} onComplete={(res) => {
                                    setFieldValue(keyMap[KEY_ROUGH_ADDRESS], res.roadAddress);
                                    setFieldValue(keyMap[KEY_POSTAL_CODE], res.zonecode);
                                    setIsSearchModalOpen(false);
                                    if (detailAddressInputRef.current) {
                                        detailAddressInputRef.current.focus();
                                        setTimeout(() => {
                                            detailAddressInputRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                                        }, 0);
                                    }
                                }}/>
                            </div>
                </Modal>
            }
            {
                isAddressListModalOpen && !!user &&
                <Modal width={isMobile ? '100%' : 416} height={isMobile ? '100%' : undefined} isOpen={isAddressListModalOpen}
                       close={() => setIsAddressListModalOpen(false)}>
                    {
                        <AddressList
                            defaultScreen={null}
                            onChangeAddress={(e) => {
                                onChangeAddress(e);
                                if (!deliveryMemoChoices.includes(e.memo) && e.memo !== '') {
                                    setSelfEnter(true);
                                } else {
                                    setSelfEnter(false);
                                }
                            }}
                            close={() => setIsAddressListModalOpen(false)}/>
                    }
                </Modal>
            }
            {
                !values[keyMap[KEY_ADDRESS_ID]] ?
                    <div style={{marginBottom: 8}}>
                        <div className={checkoutStyles.inputContainer}>
                            <span className={checkoutStyles.inputLabel}>{t('CheckoutAddressCard.receiverName')}</span>
                            <div className={checkoutStyles.inputWrapper}>
                                <TextInput
                                    ref={nameInputRef}
                                    onEnter={e => findNextInputAndFocus(e.target)}
                                    error={errors[keyMap[KEY_RECEIVER_NAME]] && touched[keyMap[KEY_RECEIVER_NAME]]}
                                    placeholder={t('CheckoutAddressCard.enterName')}
                                    readonly={isLoading}
                                    value={values[keyMap[KEY_RECEIVER_NAME]]}
                                    onChange={(e) => setFieldValue(keyMap[KEY_RECEIVER_NAME], e)}/>
                            </div>
                            {
                                (errors[KEY_RECEIVER_NAME] && touched[KEY_RECEIVER_NAME]) &&
                                <InputError message={errors[KEY_RECEIVER_NAME]}/>
                            }
                        </div>
                        <div className={checkoutStyles.inputContainer}>
                            <span className={checkoutStyles.inputLabel}>{t('CheckoutAddressCard.phoneNumber')}</span>
                            <div className={checkoutStyles.inputWrapper}>
                                <TextInput
                                    onEnter={e => findNextInputAndFocus(e.target)}
                                    type="tel"
                                    error={errors[keyMap[KEY_RECEIVER_PHONE_NUMBER]] && touched[keyMap[KEY_RECEIVER_PHONE_NUMBER]]}
                                    placeholder={t('CheckoutAddressCard.enterPhoneNumber')}
                                    onChange={e => {
                                        if (PHONE_NUMBER_REGEX.test(e) || !e) {
                                            setFieldValue(keyMap[KEY_RECEIVER_PHONE_NUMBER], e || '');
                                        }
                                    }}
                                    value={values[keyMap[KEY_RECEIVER_PHONE_NUMBER]]} readonly={isLoading}/>
                            </div>
                            {
                                (errors[KEY_RECEIVER_PHONE_NUMBER] && touched[KEY_RECEIVER_PHONE_NUMBER]) &&
                                <InputError message={errors[KEY_RECEIVER_PHONE_NUMBER]}/>
                            }
                        </div>
                        <div className={classNames(checkoutStyles.inputContainer, checkoutStyles.narrowContainer)}>
                            <span className={checkoutStyles.inputLabel}>{t('CheckoutAddressCard.shippingAddress')}</span>
                            <div className={checkoutStyles.inputWrapper}
                                 onClick={() => !isLoading && setIsSearchModalOpen(true)}>
                                <div style={{flex: 2}}><TextInput
                                    placeholder={t('CheckoutAddressCard.enterAddress')}
                                    disabled
                                    error={errors[keyMap[KEY_POSTAL_CODE]] && touched[keyMap[KEY_POSTAL_CODE]]}
                                    value={values[keyMap[KEY_POSTAL_CODE]]} /></div>
                                <button className={styles.findAddress}>
                                    {t('CheckoutAddressCard.findAddress')}
                                </button>
                            </div>
                        </div>
                        {
                            !!values[keyMap[KEY_POSTAL_CODE]] &&
                            <>
                                <div className={classNames(checkoutStyles.inputContainer, checkoutStyles.narrowContainer)}>
                                    <span className={classNames(checkoutStyles.inputLabel, checkoutStyles.emptyLabel)}/>
                                    <div className={checkoutStyles.inputWrapper}
                                         onClick={() => !isLoading && setIsSearchModalOpen(true)}><TextInput
                                        disabled
                                        error={errors[keyMap[KEY_ROUGH_ADDRESS]] && touched[keyMap[KEY_ROUGH_ADDRESS]]}
                                        value={values[keyMap[KEY_ROUGH_ADDRESS]]} /></div>
                                    {
                                        (errors[KEY_ROUGH_ADDRESS] && touched[KEY_ROUGH_ADDRESS]) &&
                                        <InputError message={errors[KEY_ROUGH_ADDRESS]}/>
                                    }
                                </div>
                                <div className={checkoutStyles.inputContainer}>
                                    <span className={classNames(checkoutStyles.inputLabel, checkoutStyles.emptyLabel)}/>
                                    <div className={checkoutStyles.inputWrapper}><TextInput
                                        ref={detailAddressInputRef}
                                        error={errors[keyMap[KEY_DETAIL_ADDRESS]] && touched[keyMap[KEY_DETAIL_ADDRESS]]}
                                        readonly={isLoading}
                                        placeholder={t('CheckoutAddressCard.enterDetailAddress')}
                                        maxLength={40}
                                        value={values[keyMap[KEY_DETAIL_ADDRESS]]}
                                        onChange={(e) => setFieldValue(keyMap[KEY_DETAIL_ADDRESS], e)}
                                    /></div>
                                    {
                                        (errors[KEY_DETAIL_ADDRESS] && touched[KEY_DETAIL_ADDRESS]) &&
                                        <InputError message={errors[KEY_DETAIL_ADDRESS]}/>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    :
                    <>
                        {
                            user && values[keyMap[KEY_ADDRESS_ID]] && values[keyMap[KEY_ADDRESS_ID]] === user.default_address_id &&
                            <div className={styles.titleContainer}>
                                <div className={styles.defaultAddressContainer}>
                                    <span className={styles.defaultAddressMark}>{t('CheckoutAddressCard.defaultAddress')}</span>
                                </div>
                            </div>
                        }
                        <div className={styles.nameContainer}>
                            <span className={classNames(styles.receiver, styles.receiverName)}>{values[keyMap[KEY_RECEIVER_NAME]]}</span>
                            <PipeIcon className={styles.pipe}/>
                            <span className={styles.receiver}>{values[keyMap[KEY_RECEIVER_PHONE_NUMBER]]}</span>
                        </div>
                        <span className={styles.address}
                              style={{marginBottom: 16}}>({values[keyMap[KEY_POSTAL_CODE]]}) {values[keyMap[KEY_ROUGH_ADDRESS]]} {values[keyMap[KEY_DETAIL_ADDRESS]]}</span>
                    </>
                }
            {
                !(!values[keyMap[KEY_ADDRESS_ID]] && !values[keyMap[KEY_POSTAL_CODE]]) &&
                <>
                    {
                        countryCode === 'KR' &&
                        <div className={checkoutStyles.memoContainer}>
                            <div className={checkoutStyles.inputWrapper}>
                                <TextInputDropdown
                                    placeholder={t('CheckoutAddressCard.selectDeliveryRequest')}
                                    choices={deliveryMemoChoices.concat([VALUE_SELF_ENTER])}
                                    onChange={onDeliveryMemoChoiceClick}
                                    value={selfEnter ? VALUE_SELF_ENTER : values[keyMap[KEY_DELIVERY_MEMO]]}/>
                            </div>
                        </div>
                    }
                    {
                        selfEnter &&
                        <>
                            <div style={{height: 8}} />
                            <TextInput
                                placeholder={t('CheckoutAddressCard.enterWithin50Chars')}
                                maxLength={50}
                                value={values[keyMap[KEY_DELIVERY_MEMO]]}
                                onChange={(e) => setFieldValue(keyMap[KEY_DELIVERY_MEMO], e)}/>
                        </>
                    }
                </>
            }
        </div>
    );
}
