import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import utilStyles from "styles/utils.module.scss";
import UncheckedCheckBoxIcon from "images/icons/checkbox_unchecked.svg";
import CheckedCheckBoxIcon from "images/icons/checkbox_checked.svg";
import classNames from "classnames";
import Link from "next/link";
import {privacyPolicyRoute} from "common/const";
import BulletText from "components/BulletText";
import {KEY_AGREEMENT_CHECKED, KEY_PG} from "pages/store/orders/[ordNum]/checkout";
import {useContext} from "react";
import UserContext from "context/AuthContext";


const KRCheckoutAgreementSection = (props) => {
    const {formik, finalPrice, isReservation, order} = props;

    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const onAgreeAllClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageAgreeAllClick', {isAgreeAll: values[KEY_AGREEMENT_CHECKED]});
        setFieldValue(KEY_AGREEMENT_CHECKED, !values[KEY_AGREEMENT_CHECKED]);
    };

    const onShowDetailTextClick = (type) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageShowTermsDetailTextClick', {type: type});
    };

    const customCautionList = order?.option_count_list?.map((optionCount) => {
        return optionCount.product?.extra_data?.purchase_caution_list;
    }).filter((caution) => caution).reduce((acc, cur) => acc.concat(cur), []);

    const cautionList = customCautionList?.length > 0 ? customCautionList : [
        '헤메티켓은 쿠폰 할인 및 포인트 할인이 불가능한 상품입니다.',
        '헤메티켓은 환불 및 교환이 불가능한 상품임을 확인하였으며 이에 동의합니다.',
        '헤메티켓 상품 페이지에 명시된 유의사항을 확인하였으며 이에 동의합니다. '
    ];

    return (
        <div className={styles.agreementContainer}>
            <div onClick={onAgreeAllClick} className={utilStyles.cursorPointer}>
                {
                    !values[KEY_AGREEMENT_CHECKED] ?
                        <UncheckedCheckBoxIcon className={styles.checkbox}/> :
                        <CheckedCheckBoxIcon className={styles.checkbox}/>
                }
                <span className={styles.agree}>
                    {isReservation ? '약관과 유의사항 확인 및 동의' : '약관 모두 동의'}
                </span>
            </div>
            <div className={styles.agreementBorder}/>
            <div
                className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter, styles.agreementRow)}>
                <span className={styles.agreeTitle}>[필수] 개인정보 수집 및 이용</span>
                <Link href={privacyPolicyRoute}>
                    <a target="_blank" className={styles.agreeSeeMore}
                       onClick={() => onShowDetailTextClick('[필수] 개인정보 수집 및 이용')}
                    >내용보기</a>
                </Link>
            </div>
            {
                values[KEY_PG] === 'uplus' && finalPrice !== 0 &&
                <>
                    <div style={{height: 8}}/>
                    <div
                        className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter, styles.agreementRow)}>
                        <span className={styles.agreeTitle}>[필수] 결제대행 서비스 이용약관 동의</span>
                    </div>
                    <div
                        className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter, styles.agreementRow, styles.agreementSubRow)}>
                        <BulletText>
                            <span className={classNames(styles.agreeTitle, styles.agreeSubtitle)}>전자금융거래 기본약관</span>
                        </BulletText>
                        <a href="https://pages.tosspayments.com/terms/user" target="_blank"
                           className={styles.agreeSeeMore}
                           onClick={() => onShowDetailTextClick('전자금융거래 기본약관')}
                        >내용보기</a>
                    </div>
                    <div
                        className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter, styles.agreementRow, styles.agreementSubRow)}>
                        <BulletText>
                            <span className={classNames(styles.agreeTitle, styles.agreeSubtitle)}>개인정보 수집이용 동의</span>
                        </BulletText>
                        <a href="https://pages.tosspayments.com/terms/privacy" target="_blank"
                           className={styles.agreeSeeMore}
                           onClick={() => onShowDetailTextClick('개인정보 수집이용 동의')}
                        >내용보기</a>
                    </div>
                    <div
                        className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter, styles.agreementRow, styles.agreementSubRow)}>
                        <BulletText>
                            <span className={classNames(styles.agreeTitle, styles.agreeSubtitle)}>개인정보 제3자 제공 동의</span>
                        </BulletText>
                        <a href="https://pages.tosspayments.com/terms/privacy2" target="_blank"
                           className={styles.agreeSeeMore}
                           onClick={() => onShowDetailTextClick('개인정보 제3자 제공 동의')}
                        >내용보기</a>
                    </div>
                </>
            }
            {
                isReservation &&
                <div className={styles.hemeticketWarningContainer}>
                    <div className={styles.hemeticketWarningTitle}>[추가 유의사항 확인 및 동의]</div>
                    {
                        cautionList.map((caution, index) => {
                            return (
                                <BulletText key={`caution-${index}`} fontColor={'#FF5D5D'}>
                                    <span className={classNames(styles.hemeticketWarning, styles.agreeSubtitle)}>{caution}</span>
                                </BulletText>
                            )
                        })
                    }
                </div>
            }
            <span className={styles.totalAgree}>위 내용을 확인 하였으며, 약관 전체에 동의 합니다.</span>
        </div>
    )
};

export default KRCheckoutAgreementSection;
