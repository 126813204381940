import parentStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import styles from "components/sections/checkout/CheckoutGiftReceiverSection.module.scss";
import TextInput from "components/input/TextInput";
import {KEY_GIFT_RECEIVER_NAME, KEY_RECEIVER_NAME} from "pages/store/orders/[ordNum]/checkout";
import InputError from "components/input/InputError";

const CheckoutGiftReceiverSection = (props) => {
    const {formik} = props;

    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;


    return (
        <div className={parentStyles.section}>
            <span className={styles.title}>받는 분</span>
            <TextInput placeholder="이름을 입력해주세요" maxLength={50} value={values[KEY_GIFT_RECEIVER_NAME]}
                       error={errors[KEY_GIFT_RECEIVER_NAME] && touched[KEY_GIFT_RECEIVER_NAME]}
                       onChange={(e) => setFieldValue(KEY_GIFT_RECEIVER_NAME, e)}/>
            {
                (errors[KEY_GIFT_RECEIVER_NAME] && touched[KEY_GIFT_RECEIVER_NAME]) &&
                <InputError message={errors[KEY_GIFT_RECEIVER_NAME]}/>
            }
        </div>
    )
}

export default CheckoutGiftReceiverSection;
